import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    editSuccess: 'vacancy.autoUpdate.editSuccess',
    removeSuccess: 'vacancy.autoUpdate.removeSuccess',
    scheduleSuccess: 'vacancy.autoUpdate.scheduleSuccess',
};

interface AutoUpdatesRemovedProps {
    vacancyName: string;
    vacancyId: number;
}

const AutoUpdatesRemoved: TranslatedComponent<AutoUpdatesRemovedProps> = ({ trls, vacancyName, vacancyId }) =>
    formatToReactComponent(trls[TrlKeys.removeSuccess], {
        '{0}': (
            <BlokoLink disableVisited Element={Link} to={`/vacancy/${vacancyId}`}>
                {vacancyName}
            </BlokoLink>
        ),
    });

export default {
    Element: translation(AutoUpdatesRemoved),
    kind: 'ok',
    autoClose: true,
};
