import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { Upgrade } from 'src/models/prolongateInfo';
import MetallicVacancyType from 'src/models/vacancy/metallicVacancyType.types';

import prolongateUpgradeVacancy from 'src/components/VacancyModals/Prolongation/prolongateUpgradeVacancy';

interface UpgradeButtonProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    vacancyUpgrade: Upgrade;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    additionalAnalyticsParams?: Record<string, string>;
}

const TrlKeys = {
    deduct: 'vacancy.prolongate.submit.deduct',
};

const UpgradeButton: TranslatedComponent<UpgradeButtonProps> = ({
    afterSuccessAction,
    vacancyUpgrade,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    additionalAnalyticsParams = {},
    trls,
}) => {
    // Блок upgrade рисуется только вместе с покупкой
    const vacancyIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds);

    const { addNotification } = useNotification();

    const upgrade = async (targetMetallic: MetallicVacancyType) => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick('modal_prolongation_link', {
            ...additionalAnalyticsParams,
            vacanciesIds: vacancyIds,
        });

        startLoading();
        try {
            const queryParams = await prolongateUpgradeVacancy({
                addNotification,
                targetMetallic,
                vacancyId: vacancyIds[0],
            });

            if (afterSuccessAction) {
                afterSuccessAction({
                    handleCloseModal,
                    queryParams,
                    vacancyIds,
                });

                return;
            }
            // Нельзя написать в finally, т.к. будет закрывать любую модалку, в т.ч. открытую в afterSuccessAction
            handleCloseModal();
        } catch (ignore) {
            console.error(ignore);
            handleCloseModal();
        } finally {
            finishLoading();
        }
    };

    return (
        <Button
            mode="primary"
            style="accent"
            size="medium"
            loading={loading}
            stretched
            data-qa={`prolongate-upgrade-button_${(vacancyUpgrade.service || '').toLowerCase()}`}
            onClick={() => upgrade(vacancyUpgrade.service)}
        >
            {format(trls[TrlKeys.deduct], { '{0}': vacancyUpgrade.serviceTrl })}
        </Button>
    );
};

export default translation(UpgradeButton);
