import { createContext } from 'react';
import { scaleLinear } from 'd3-scale';

import { XAxis } from 'src/components/VacancyView/SalaryMarketRangeChart/types';

interface ChartContextType {
    xAxis: XAxis;
    hOffset: number;
}

const ChartContext = createContext<ChartContextType>({ xAxis: scaleLinear([0]), hOffset: 0 });

export default ChartContext;
