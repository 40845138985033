import { Link as MagritteLink, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';

interface VacancyRowProps {
    vacancyId: number;
    name: string;
    description: string;
}
const VacancyRow = ({ name, vacancyId, description }: VacancyRowProps) => {
    return (
        <>
            <MagritteLink Element={Link} typography="label-3-regular" to={`/vacancy/${vacancyId}`} target="_blank">
                {name}
            </MagritteLink>
            <VSpacing default={4} />
            <Text style="secondary" typography="label-3-regular">
                {description}
            </Text>
        </>
    );
};

export default translation(VacancyRow);
