import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { upgradeManagerVacancy } from 'src/models/employerVacancies/managerVacancies';
import fetcher from 'src/utils/fetcher';

import autoUpdatesRemoved from 'src/components/VacancyModals/AutoUpdateModal/AutoUpdatesRemoveNotification';

export const removeSchedule = (params, vacancyName, addNotification) => async (dispatch) => {
    let vacancy;

    try {
        await fetcher.delete('/shards/employer/vacancies/auto_update/schedule', { params });
        vacancy = (await fetcher.get('/shards/employer/vacancies/get_full_vacancy', { params })).vacancies?.[0];
    } catch (err) {
        defaultRequestErrorHandler(err, addNotification);

        return;
    }

    dispatch(upgradeManagerVacancy({ vacancy }));
    addNotification(autoUpdatesRemoved, { props: { vacancyId: params.vacancyId, vacancyName } });
};
