import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';
import { CurrencyType } from 'src/models/currencies.types';
import { ServiceItemCode } from 'src/models/price/productCodes';
import { PublicationBillingCode } from 'src/models/prolongateInfo';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import VacancyItemIcon from 'src/components/EmployerVacancies/VacancyItem/VacancyItemIcon';

import styles from './vacancy-item.less';

export interface VacancyItemProps {
    billingCode: PublicationBillingCode;
    currency?: CurrencyType;
    region?: string;
    profRoleGroup?: string;
    price?: number;
    amount: number;
}

const TrlKeys = {
    anyRegion: 'any.region',
    anyProfRoleGroup: 'any.profRoleGroup',
    unit: 'employer.vacancyRisePage.cart.unit',
    title: {
        [ServiceItemCode.Standart]: 'service.entry.VP.short',
        [ServiceItemCode.StandartPlus]: 'service.entry.RENEWAL_VP.short',
        [ServiceItemCode.Premium]: 'service.entry.VPREM.short',
        [ServiceItemCode.FreeRegionalVacancy]: 'service.entry.RFPN.short',
        [ServiceItemCode.FreeVacancy]: 'service.entry.FPN.short',
        [ServiceItemCode.Anonymous]: 'service.entry.AP.short',
        [ServiceItemCode.Advertizing]: 'service.entry.ADN.short',
        [ServiceItemCode.ZpPromo]: 'service.entry.ZP_PROMO.short',
        [ServiceItemCode.ZpBusiness]: 'service.entry.ZP_BUSINESS.short',
        [ServiceItemCode.ZpAnonymous]: 'service.entry.ZP_ANONYMOUS.short',
    },
};

const VacancyItem: TranslatedComponent<VacancyItemProps> = ({
    billingCode,
    currency,
    region,
    profRoleGroup,
    price,
    amount,
    trls,
}) => {
    const [isRegionExpanded, toggleIsRegionExpanded] = useToggleState(false);
    const [isProfRoleGroupExpanded, toggleIsProfRoleGroupExpanded] = useToggleState(false);

    return (
        <div className={styles.productCell}>
            <VacancyItemIcon billingCode={billingCode} />
            <div className={styles.content}>
                <div className={styles.titlePrice}>
                    <Text typography="subtitle-2-semibold" maxLines={1}>
                        {trls[TrlKeys.title[billingCode]]}
                    </Text>
                    {price && currency && (
                        <Text typography="label-3-regular">
                            <FormatMoney currency={currency} groupSeparator={NON_BREAKING_SPACE} inCents>
                                {price}
                            </FormatMoney>
                        </Text>
                    )}
                </div>
                <div className={styles.descriptionCount}>
                    <div className={styles.description}>
                        <div className={styles.expandableText}>
                            <Text
                                typography="label-3-regular"
                                style="secondary"
                                maxLines={isRegionExpanded ? undefined : 1}
                                onClick={toggleIsRegionExpanded}
                            >
                                {region || trls[TrlKeys.anyRegion]}
                            </Text>
                        </div>
                        <div className={styles.expandableText}>
                            <Text
                                typography="label-3-regular"
                                style="secondary"
                                maxLines={isProfRoleGroupExpanded ? undefined : 1}
                                onClick={toggleIsProfRoleGroupExpanded}
                            >
                                {profRoleGroup || trls[TrlKeys.anyProfRoleGroup]}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.amount}>
                        <Text typography="label-3-regular" style="secondary">
                            {`${amount}${NON_BREAKING_SPACE}${trls[TrlKeys.unit]}`}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translation(VacancyItem);
