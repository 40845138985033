import { useRef, useEffect, FC, useContext } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'src/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';
import {
    CHART_HEIGHT,
    RANGE_TO_PERCENT,
    MAX_CHART_PERCENT,
} from 'src/components/VacancyView/SalaryMarketRangeChart/constants';
import { ChartProps, SalaryGradient } from 'src/components/VacancyView/SalaryMarketRangeChart/types';
import { useChartData } from 'src/components/VacancyView/SalaryMarketRangeChart/utils';
import withChartContainer from 'src/components/VacancyView/SalaryMarketRangeChart/withChartContainer';
import { VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';
import { isSafariUserAgent } from 'src/utils/isSafariUserAgent';

import styles from './salary-range-chart.less';

const CHART_RADIUS = 12;
const CHART_BACKGROUND_RADIUS = 20;

const RECT_HEIGHT = 24;
const OFFSET_Y_RECT = 8;

const getLinearGradient = (gradient: SalaryGradient | null) => {
    const xCoords = gradient === SalaryGradient.In ? { x1: '100%', x2: 0 } : { x: '0%', x2: '100%' };
    return (
        <linearGradient id="linear-gradient" {...xCoords} y1="0%" y2="0%">
            <stop offset="0%" stopColor="var(--fill-color)" stopOpacity="0%" />
            <stop offset="40%" stopColor="var(--fill-color)" stopOpacity="10%" />
            <stop offset="100%" stopColor="var(--fill-color)" stopOpacity="100%" />
        </linearGradient>
    );
};

interface SalaryRangeChartProps extends ChartProps {
    compensation: Partial<VacancyCompensation>;
}

const SalaryRangeChart: FC<SalaryRangeChartProps> = ({ data, compensation }) => {
    const { xAxis, hOffset } = useContext(ChartContext);
    const rectChartRef = useRef<SVGGElement | null>(null);

    const { from, to, gradient, isOutOfRange } = useChartData(data, compensation);
    const hasGradient = Object.values(SalaryGradient).includes(gradient);
    const isSafari = isSafariUserAgent();

    useEffect(() => {
        if (!rectChartRef.current) {
            return undefined;
        }

        const gRect = select(rectChartRef.current);
        const rect = gRect.selectAll('rect').data(RANGE_TO_PERCENT).enter();

        const addBackground = () => {
            gRect
                .append('rect')
                .attr('x', -hOffset)
                .attr('y', 0)
                .attr('width', xAxis(MAX_CHART_PERCENT) + hOffset * 2)
                .attr('height', CHART_HEIGHT)
                .attr('rx', CHART_BACKGROUND_RADIUS)
                .attr('class', styles.chartBackground);
        };

        if ((from !== 0 && from === to) || isOutOfRange) {
            addBackground();

            gRect
                .append('circle')
                .attr('fill', 'var(--fill-color)')
                .attr('stroke', 'none')
                .attr('cx', xAxis(from))
                .attr('cy', CHART_HEIGHT / 2)
                .attr('r', isOutOfRange ? 6 : CHART_RADIUS);
        }

        if (!isOutOfRange && from !== to) {
            const rectSalaryShadow = gRect
                .append('rect')
                .attr('x', xAxis(from))
                .attr('y', isSafari ? -4 : 0)
                .attr('height', isSafari ? CHART_HEIGHT + 8 : CHART_HEIGHT)
                .attr('width', xAxis(to) - xAxis(from))
                .attr('fill', 'var(--fill-color)');

            // salary-shadow
            if (isSafari) {
                // safari doesn`t support css filter with blur on svg elements
                rectSalaryShadow.attr('filter', 'url(#blur)').attr('fill-opacity', '0.3');
            } else {
                rectSalaryShadow.attr('class', styles.filter);
            }
            addBackground();

            // salary
            gRect
                .append('rect')
                .attr('x', xAxis(from))
                .attr('y', OFFSET_Y_RECT)
                .attr('width', xAxis(to) - xAxis(from))
                .attr('height', RECT_HEIGHT)
                .attr('rx', CHART_RADIUS)
                .attr('fill', hasGradient ? 'url(#linear-gradient)' : 'var(--fill-color)');
        }

        if (from === 0 && from === to) {
            addBackground();
        }

        // ranges
        rect.append('rect')
            .attr('x', (d) => xAxis(d))
            .attr('y', 10)
            .attr('width', 2)
            .attr('height', 20)
            .attr('rx', 2)
            .attr('class', styles.rangeColor);

        return () => {
            ['rect', 'circle'].forEach((el) => gRect.selectAll(el).remove());
        };
    }, [xAxis, from, to, hasGradient, isOutOfRange, isSafari, hOffset]);

    return (
        <>
            {isSafari && (
                <defs>
                    <filter id="blur">
                        <feGaussianBlur stdDeviation="6" />
                    </filter>
                </defs>
            )}
            <g ref={rectChartRef}>{hasGradient && getLinearGradient(gradient)}</g>
        </>
    );
};

export default withChartContainer<SalaryRangeChartProps>(SalaryRangeChart);
