export enum ContactsAction {
    Update = 'SET_NEW_FOR_ALL',
    Hide = 'SET_NULL_FOR_ALL',
    DontChange = 'DO_NOTHING',
}

export type FormType = 'delete' | 'move';

export interface FormValues {
    fio: string;
    email: string;
    phone: string;
    comment: string;
    additionalPhone: string;
    additionalComment: string;
}

export enum FieldName {
    Fio = 'fio',
    Email = 'email',
    Phone = 'phone',
    Comment = 'comment',
    AdditionalPhone = 'additionalPhone',
    AdditionalComment = 'additionalComment',
}

export interface ResponseError {
    email?: {
        errors: string[];
    };
    fio?: {
        errors: string[];
    };
    phones?: {
        phone: {
            '0'?: {
                fullPhoneNumber: {
                    errors: string[];
                };
            };
            '1'?: {
                fullPhoneNumber: {
                    errors: string[];
                };
            };
        };
    };
    phoneOrEmailRule?: {
        errors: string[];
    };
}
