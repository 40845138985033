import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    titleDescription: 'vacancy.contacts.change.modal.title.description',
    titleDescriptionMany: 'vacancy.contacts.change.modal.title.description.many',
    titleDescriptionDelete: 'vacancy.contacts.change.modal.title.delete.manager.description',
};

const TitleDescriptionComponent: TranslatedComponent<{ vacanciesCount?: number }> = ({ trls, vacanciesCount }) => {
    const { isMobile } = useBreakpoint();

    if (!vacanciesCount) {
        return (
            <Text typography={isMobile ? 'label-2-regular' : 'paragraph-1-regular'} style="secondary">
                {trls[TrlKeys.titleDescriptionDelete]}
            </Text>
        );
    }

    return (
        <Text typography={isMobile ? 'label-2-regular' : 'paragraph-1-regular'} style="secondary">
            <ConversionNumber
                hasValue={false}
                value={vacanciesCount}
                one={trls[TrlKeys.titleDescription]}
                some={trls[TrlKeys.titleDescriptionMany]}
                many={trls[TrlKeys.titleDescriptionMany]}
            />
        </Text>
    );
};

export default translation(TitleDescriptionComponent);
