import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import fetcher from 'src/utils/fetcher';

export const getManagersListTreeCollection = (managersList, currentGroupManagersIdsSet) =>
    fromTree(
        managersList
            .filter(({ id }) => !currentGroupManagersIdsSet.has(id))
            .map(({ id, name, firstName, middleName, lastName }) => ({
                id: `${id}`,
                text: name,
                additional: {
                    managerId: id,
                    firstName,
                    middleName,
                    lastName,
                },
            }))
    );

export const getFilteredGroupManagerIdsSet = (managerVacanciesFilteredGroups, activeGroupId) =>
    new Set(
        managerVacanciesFilteredGroups
            .find(({ groupId }) => groupId === activeGroupId)
            ?.employerManagers?.map(({ employerManagerId }) => employerManagerId)
    );

const fetchManagersListSuccess = makeSetStoreField('managersList');

export default ({ params }) =>
    async (dispatch) => {
        const managersList = await fetcher.get('/employer/resumefolders/managers', { params });

        return dispatch(fetchManagersListSuccess(managersList.data));
    };
