import { Segment, Segmented, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

export type SelectedSegment = 'purchase' | 'deduct';

interface SegmentedControlProps {
    visible?: boolean;
    selectedSegment: SelectedSegment;
    handleChangeSelectedSegment: (value: SelectedSegment) => void;
}

const TrlKeys = {
    purchase: 'vacancy.prolongate.segmented.purchase',
    deduct: 'vacancy.prolongate.segmented.deduct',
};

const SegmentedControl: TranslatedComponent<SegmentedControlProps> = ({
    selectedSegment,
    handleChangeSelectedSegment,
    trls,
}) => {
    const availableToUpgrade = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToUpgrade);
    const { isGtS } = useBreakpoint();

    if (!availableToUpgrade) {
        return null;
    }

    return (
        <>
            <Segmented
                mode="label"
                size="small"
                stretched
                selectedValue={selectedSegment}
                onChange={(event) => handleChangeSelectedSegment(event.target.value as SelectedSegment)}
            >
                <Segment value="purchase" data-qa="purchase_tab">
                    {trls[TrlKeys.purchase]}
                </Segment>
                <Segment value="deduct" data-qa="use_from_balance_tab">
                    {trls[TrlKeys.deduct]}
                </Segment>
            </Segmented>
            {isGtS && <VSpacing default={24} />}
        </>
    );
};

export default translation(SegmentedControl);
