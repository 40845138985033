// tempexp_34940_file
import { FC } from 'react';

import { Cell, VSpacing } from '@hh.ru/magritte-ui';

const LoaderAdviceTitleDescription: FC = () => {
    return (
        <div>
            <VSpacing default={6} />
            <Cell.Skeleton height={16} borderRadius={4} loading width={240} />
            <VSpacing default={8} />
            <Cell.Skeleton height={16} borderRadius={4} loading width={220} />
            <VSpacing default={6} />
        </div>
    );
};

export default LoaderAdviceTitleDescription;
