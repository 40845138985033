import { FC } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import { useClassificationChangesPage } from 'src/components/EmployerVacancies/hooks/useClassificationChangesPage';
import { Upgrade as UpgradeType } from 'src/models/prolongateInfo';

import AvailableToFreeProlongate from 'src/components/VacancyModals/Prolongation/AvailableToFreeProlongate';
import AvailableToPurchase from 'src/components/VacancyModals/Prolongation/AvailableToPurchase';
import AvailableToSpend from 'src/components/VacancyModals/Prolongation/AvailableToSpend';
import SegmentedControl, { SelectedSegment } from 'src/components/VacancyModals/Prolongation/SegmentedControl';
import Unavailable from 'src/components/VacancyModals/Prolongation/Unavailable';
import Upgrade from 'src/components/VacancyModals/Prolongation/Upgrade';

interface ContentProps {
    vacancyUpgrade: UpgradeType | null;
    selectedSegment: SelectedSegment;
    handleChangeSelectedSegment: (value: SelectedSegment) => void;
    handleChangeVacancyUpgrade: (value: UpgradeType) => void;
    handleClickMoreDetails: () => void;
}

const Content: FC<ContentProps> = ({
    vacancyUpgrade,
    selectedSegment,
    handleChangeSelectedSegment,
    handleChangeVacancyUpgrade,
    handleClickMoreDetails,
}) => {
    const { isGtS } = useBreakpoint();

    // tempexp_32484_next_line
    const { isExpClassificationChangesPage } = useClassificationChangesPage();

    return (
        <>
            {/* tempexp_32484_next_line */}
            {isGtS && !isExpClassificationChangesPage && (
                <>
                    <SegmentedControl
                        selectedSegment={selectedSegment}
                        handleChangeSelectedSegment={handleChangeSelectedSegment}
                    />
                </>
            )}
            <Unavailable handleClickMoreDetails={handleClickMoreDetails} />
            <AvailableToSpend />
            <AvailableToPurchase selectedSegment={selectedSegment} />
            <AvailableToFreeProlongate />
            {/* tempexp_32484_next_line */}
            {!isExpClassificationChangesPage && (
                <Upgrade
                    selectedSegment={selectedSegment}
                    vacancyUpgrade={vacancyUpgrade}
                    handleChangeVacancyUpgrade={handleChangeVacancyUpgrade}
                />
            )}
        </>
    );
};

export default Content;
