import { VacancyAdvice, VacancyAdviceWithAnchor, VacancySalaryAdvice } from 'src/models/vacancy/vacancyAdvices.types';

export enum VacancyAdviceGroup {
    Important,
    Paid,
    Free,
}

export const VacancyAdviceToGroup: Record<VacancyAdvice, VacancyAdviceGroup> = {
    [VacancyAdvice.SalaryAboveMarket]: VacancyAdviceGroup.Important,
    [VacancyAdvice.SalaryCloserToMarket]: VacancyAdviceGroup.Important,
    [VacancyAdvice.SalaryMoreSpecific]: VacancyAdviceGroup.Important,
    [VacancyAdvice.PublishSalary]: VacancyAdviceGroup.Important,
    [VacancyAdvice.ChangeVacancyName]: VacancyAdviceGroup.Important,
    [VacancyAdvice.UseResumeSearch]: VacancyAdviceGroup.Paid,
    [VacancyAdvice.UseSearchTop]: VacancyAdviceGroup.Paid,
    [VacancyAdvice.UseStandardPlus]: VacancyAdviceGroup.Paid,
    [VacancyAdvice.WorkAddress]: VacancyAdviceGroup.Free,
    [VacancyAdvice.ConditionsToDescription]: VacancyAdviceGroup.Free,
    [VacancyAdvice.RequirementsToDescription]: VacancyAdviceGroup.Free,
    [VacancyAdvice.ResponsibilitiesToDescription]: VacancyAdviceGroup.Free,
    [VacancyAdvice.EmployerContacts]: VacancyAdviceGroup.Free,
    [VacancyAdvice.DecreaseWorkExperience]: VacancyAdviceGroup.Free,
    [VacancyAdvice.RaiseVacancy]: VacancyAdviceGroup.Paid,
};

export const HIDE_ADVICE_URL = '/shards/employer/vacancies/advice/hide';

export enum Rating {
    Sad = '1',
    Halfsad = '2',
    Neutral = '3',
    Halfsmile = '4',
    Smile = '5',
}

export enum Reason {
    Difficult = 'difficult',
    Paid = 'paid',
    DontBelieve = 'dontBelieve',
    Disagree = 'disagree',
    NotRelevant = 'notRelevant',
    CantApply = 'cantApply',
    Errors = 'errors',
}

export const ADVICE_TO_ANCHOR: Record<VacancyAdviceWithAnchor, string> = {
    [VacancyAdvice.WorkAddress]: 'vacancy-advice-address',
    [VacancyAdvice.ConditionsToDescription]: 'vacancy-advice-description',
    [VacancyAdvice.RequirementsToDescription]: 'vacancy-advice-description',
    [VacancyAdvice.ResponsibilitiesToDescription]: 'vacancy-advice-description',
    [VacancyAdvice.SalaryCloserToMarket]: 'vacancy-advice-salary',
    [VacancyAdvice.SalaryAboveMarket]: 'vacancy-advice-salary',
    [VacancyAdvice.SalaryMoreSpecific]: 'vacancy-advice-salary',
    [VacancyAdvice.PublishSalary]: 'vacancy-advice-salary',
    [VacancyAdvice.EmployerContacts]: 'vacancy-advice-contacts',
    [VacancyAdvice.DecreaseWorkExperience]: 'vacancy-advice-experience',
};

export const SALARY_VACANCY_ADVICES: VacancySalaryAdvice[] = [
    VacancyAdvice.PublishSalary,
    VacancyAdvice.SalaryCloserToMarket,
    VacancyAdvice.SalaryAboveMarket,
    VacancyAdvice.SalaryMoreSpecific,
];

export const NO_PERCENT = '-';
