import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const Features = {
    disableRisePageEmployerIds: 'disable_rise_page_employer_ids',
};

interface UseShowRisePage {
    isRisePageEnabled: boolean;
}

const useShowRisePage = (): UseShowRisePage => {
    const isZP = useIsZarplataPlatform();

    const employerId = useSelector(({ employerId }) => employerId);
    const employerIds = useSelector(({ features }) => (features[Features.disableRisePageEmployerIds] as string) ?? '');
    const isEmployerEnabled = !employerIds.split(',').includes(employerId);

    return {
        isRisePageEnabled: isEmployerEnabled && !isZP,
    };
};

export default useShowRisePage;
