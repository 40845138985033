import { ChipsContainer, CustomChip, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    preset1: 'employer.unpremoderated.preset.1',
    preset2: 'employer.unpremoderated.preset.2',
    preset3: 'employer.unpremoderated.preset.3',
};

const presets = [TrlKeys.preset1, TrlKeys.preset2, TrlKeys.preset3];

interface PresetChipsProps {
    onSelect: (preset: string) => void;
}

const PresetChips: TranslatedComponent<PresetChipsProps> = ({ trls, onSelect }) => {
    const { isMobile } = useBreakpoint();

    return (
        <ChipsContainer {...(isMobile ? { scrollable: true, noWrap: true } : { scrollable: false })}>
            {presets.map((preset) => {
                return (
                    <CustomChip onClick={() => onSelect(trls[preset])} key={preset}>
                        {trls[preset]}
                    </CustomChip>
                );
            })}
        </ChipsContainer>
    );
};

export default translation(PresetChips);
