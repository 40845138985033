import { Dispatch } from 'react';
import { Location } from 'history';
import { AnyAction } from 'redux';

import { Push } from '@hh.ru/redux-spa-middleware';

import { AppStore } from 'src/app/store';
import {
    autoProlongationPurchaseSuccess,
    autoProlongationPurchaseSuccesses,
    vacancyAutoProlongationError,
    vacancyAutoProlongationErrors,
    vacancyAutoProlongationSuccess,
    vacancyAutoProlongationSuccesses,
    VacancyToggleAction,
} from 'src/components/Notifications/EmployerVacancies';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { updateManagerVacancies } from 'src/models/employerVacancies/managerVacancies';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        autoProlongation: {
            queryParams: void;
            body: {
                vacanciesIds: number[];
                withPurchasing: boolean;
                agreementId?: number;
            };
            response: {
                query: string;
            };
        };
    }
}

export interface VacancyAutoProlongationParams {
    action: VacancyToggleAction;
    vacanciesIds: number[];
    withPurchasing?: boolean;
    agreementId?: number;
    location: Location;
}

export interface VacancyAutoProlongationFunc {
    (params: VacancyAutoProlongationParams, addNotification: AddNotification, push: Push): Promise<void>;
}

export default (
        { action, vacanciesIds, withPurchasing = false, agreementId, location }: VacancyAutoProlongationParams,
        addNotification: AddNotification,
        push: Push
    ): ((dispatch: Dispatch<AnyAction>, getState: () => AppStore) => Promise<void>) =>
    (dispatch, getState) => {
        const vacanciesGroupList = getState().managerVacancies?.vacanciesGroups?.vacanciesGroupList;
        const prevVacancies: Vacancy[] = vacanciesGroupList
            ? vacanciesGroupList.flatMap((vacanciesGroup) => vacanciesGroup.vacanciesList)
            : [];
        const vacancies = prevVacancies
            .filter((item: Vacancy) => vacanciesIds.includes(item.vacancyId))
            .map((item: Vacancy) => ({
                ...item,
                ...{
                    canEnableAutoProlongation: action === VacancyToggleAction.Disable,
                    canDisableAutoProlongation: action === VacancyToggleAction.Enable,
                },
            })) as Vacancy[];

        return fetcher
            .post(`/shards/employer/vacancies/auto_prolongation/${action}` as 'autoProlongation', {
                vacanciesIds,
                withPurchasing,
                agreementId,
            })
            .then(
                (response) => {
                    if (location.pathname.match('/vacancy/')) {
                        push(`${location.pathname}${response.data.query}`);
                        return;
                    }
                    if (vacanciesIds.length === 1) {
                        addNotification(vacancyAutoProlongationSuccess, {
                            props: { name: vacancies[0].name, action },
                        });
                        withPurchasing && addNotification(autoProlongationPurchaseSuccess);
                    } else {
                        addNotification(vacancyAutoProlongationSuccesses);
                        withPurchasing && addNotification(autoProlongationPurchaseSuccesses);
                    }

                    dispatch(updateManagerVacancies({ vacancies }));
                },
                () => {
                    if (vacanciesIds.length === 1) {
                        addNotification(vacancyAutoProlongationError, { props: { name: vacancies[0].name, action } });
                    } else {
                        addNotification(vacancyAutoProlongationErrors);
                    }
                }
            );
    };
