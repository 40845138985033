import { FC } from 'react';

import { PenSquareOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';

const TrlKeys = {
    edit: 'employer.vacancy.button.edit',
};

interface EditActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    iconOnly?: boolean;
    vacancyId: number;
    employerId?: number;
}

const EditAction: TranslatedComponent<EditActionProps> = ({
    Component,
    triggerType,
    trls,
    vacancyId,
    employerId,
    additionalAnalyticsParams,
    iconOnly = false,
}) => {
    const triggerName = 'edit';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <PenSquareOutlinedSize24 />,
    });

    const url = urlParser(`/employer/vacancy/edit/${vacancyId}`);
    if (employerId) {
        url.params = {
            employerId,
        };
    }

    return (
        <Component
            isPermitted
            triggerName={triggerName}
            triggerHref={url.href}
            triggerType={triggerType}
            additionalAnalyticsParams={additionalAnalyticsParams}
            {...iconProps}
        >
            {!iconOnly && trls[TrlKeys.edit]}
        </Component>
    );
};

export default translation(EditAction);
