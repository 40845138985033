// tempexp_34940_file
import { FC } from 'react';

import { Cell, VSpacing } from '@hh.ru/magritte-ui';

const LoaderAdviceTitle: FC = () => {
    return (
        <>
            <VSpacing default={8} />
            <Cell.Skeleton height={16} borderRadius={4} loading width={350} />
            <VSpacing default={8} />
        </>
    );
};

export default LoaderAdviceTitle;
