export enum ManagerFormMode {
    Create = 'create',
    Edit = 'edit',
    Duplicate = 'duplicate',
}

// По умолчанию FinalForm все пустые значения приводит к undefined,
// если форма должна хранить пустые строки можно передать в проп parse филда IDENTITY_FUNCTION
// https://final-form.org/docs/react-final-form/types/FieldProps#parse
export const IDENTITY_FUNCTION = (value: string): string => value;
