import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { AnyAction } from 'redux';

import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    Action,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CancelUnpremoderatedVacancyModalType } from 'src/components/VacancyModals/CancelUnpremoderatedVacancyModal/types';
import {
    ADD_MODAL,
    MODAL_CANCEL_UNPREMODERATED_VACANCY,
    MODAL_FADING_TIME,
    MODAL_UNPREMODERATED_VACANCY_PREVIEW,
} from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { UnpremoderatedVacancy } from 'src/models/unpremoderatedVacancyList';

import ApproveButton from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/ApproveButton';
import ArchiveButton from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/ArchiveButton';
import CompletionButton from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/CompletionButton';
import DiscardButton from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/DiscardButton';
import EditButton from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/EditButton';
import Preview from 'src/components/VacancyModals/UnpremoderatedVacancyPreview/components/Preview';

interface UnpremoderatedVacancyModalPreviewProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    moderatorId: number;
    vacancy: UnpremoderatedVacancy;
    dispatchModal: Dispatch<AnyAction>;
}

const TrlKeys = {
    title: 'employer.unpremoderated.vacancies.vacancyPreview.title',
    moreActions: 'employer.unpremoderated.vacancies.vacancyPreview.moreActions',
};

const UnpremoderatedVacancyModalPreview: TranslatedComponent<UnpremoderatedVacancyModalPreviewProps> = ({
    moderatorId,
    isVisible,
    vacancy,
    handleCloseModal,
    dispatchModal,
    trls,
}) => {
    const { isMobile } = useBreakpoint();
    const [moreActionsVisible, setMoreActionsVisible] = useState(false);
    const timer = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    const handleCancelAction = useCallback(() => {
        timer.current = setTimeout(() => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_UNPREMODERATED_VACANCY_PREVIEW,
                    data: { moderatorId, vacancy },
                },
            });
        }, MODAL_FADING_TIME);
    }, [dispatchModal, moderatorId, vacancy]);

    const handleDiscardButtonClick = useCallback(() => {
        handleCloseModal();

        timer.current = setTimeout(() => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_CANCEL_UNPREMODERATED_VACANCY,
                    data: {
                        moderatorId,
                        vacancy,
                        type: CancelUnpremoderatedVacancyModalType.DISCARD,
                        handleCancel: handleCancelAction,
                    },
                },
            });
        }, MODAL_FADING_TIME);
    }, [moderatorId, vacancy, dispatchModal, handleCancelAction, handleCloseModal]);

    const handleCompletionButtonClick = useCallback(() => {
        handleCloseModal();

        timer.current = setTimeout(() => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_CANCEL_UNPREMODERATED_VACANCY,
                    data: {
                        moderatorId,
                        vacancy,
                        type: CancelUnpremoderatedVacancyModalType.COMPLETION,
                        handleCancel: handleCancelAction,
                    },
                },
            });
        }, MODAL_FADING_TIME);
    }, [dispatchModal, moderatorId, vacancy, handleCancelAction, handleCloseModal]);

    const handleMoreActionsClick = useCallback(() => {
        setMoreActionsVisible(true);
    }, []);

    const handleMoreActionsClose = useCallback(() => {
        setMoreActionsVisible(false);
    }, []);

    const discardButton = <DiscardButton handleClick={handleDiscardButtonClick} />;
    const editButton = <EditButton vacancy={vacancy} />;
    const completionButton = <CompletionButton handleClick={handleCompletionButtonClick} />;
    const archiveButton = (
        <ArchiveButton
            vacancy={vacancy}
            moderatorId={moderatorId}
            dispatchModal={dispatchModal}
            onCancel={handleCancelAction}
            handleCloseModal={handleCloseModal}
        />
    );
    const approveButton = (
        <ApproveButton
            vacancy={vacancy}
            moderatorId={moderatorId}
            handleClick={handleCloseModal}
            size={isMobile ? 'large' : 'small'}
        />
    );
    const moreActionsButton = (
        <Button mode="tertiary" size="large" style="accent" onClick={handleMoreActionsClick}>
            {trls[TrlKeys.moreActions]}
        </Button>
    );

    const content = (
        <Preview moderatorId={moderatorId} vacancy={vacancy} shouldFetchPreview={isVisible && !moreActionsVisible} />
    );

    return (
        <>
            <Modal
                footer={
                    <ActionBar
                        primaryActions={
                            <>
                                {completionButton}
                                {editButton}
                                {approveButton}
                            </>
                        }
                        secondaryActions={
                            <>
                                {discardButton}
                                {archiveButton}
                            </>
                        }
                    />
                }
                onClose={handleCloseModal}
                visible={isVisible}
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                title={trls[TrlKeys.title]}
            >
                {content}
            </Modal>

            {isMobile && (
                <BottomSheet
                    footer={
                        <BottomSheetFooter>
                            {approveButton}
                            {discardButton}
                            {moreActionsButton}
                        </BottomSheetFooter>
                    }
                    header={
                        <NavigationBar
                            right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                            showDivider="always"
                            title={trls[TrlKeys.title]}
                        />
                    }
                    onClose={handleCloseModal}
                    visible={isVisible && !moreActionsVisible}
                >
                    {content}
                </BottomSheet>
            )}

            <BottomSheet
                footer={
                    <BottomSheetFooter>
                        {editButton}
                        {completionButton}
                        {archiveButton}
                    </BottomSheetFooter>
                }
                onClose={handleMoreActionsClose}
                visible={isMobile && isVisible && moreActionsVisible}
            />
        </>
    );
};

export default translation(UnpremoderatedVacancyModalPreview);
