import { Button, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FreeRenewalTip, { getNextFreeRenewalInterval } from 'src/components/VacancyActions/FreeRenewalTip';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './free-prolongation-timer.less';

interface FreeProlongationTimerProps {
    freeRenewalParameters: {
        intervalMinutes: string | null;
        lastActivationTimeIso: string | null;
    };
}

const TrlKeys = {
    prolongateFree: 'vacancy.prolongate.submit.prolongateFree',
};

const FreeProlongationTimer: TranslatedComponent<FreeProlongationTimerProps> = ({ freeRenewalParameters, trls }) => {
    const vacancyIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );

    if (
        !freeRenewalParameters.lastActivationTimeIso ||
        !freeRenewalParameters.intervalMinutes ||
        // Доступно бесплатное поднятие
        vacancyIds.length > 0
    ) {
        return null;
    }

    const nextFreeProlongationInterval = getNextFreeRenewalInterval(
        new Date(freeRenewalParameters.lastActivationTimeIso),
        Number(freeRenewalParameters.intervalMinutes)
    );

    if (nextFreeProlongationInterval <= 0) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <Button size="medium" style="accent" mode="primary" disabled stretched>
                {trls[TrlKeys.prolongateFree]}
            </Button>
            <Text typography="label-3-regular" style="secondary">
                <FreeRenewalTip
                    freeRenewalInterval={freeRenewalParameters.intervalMinutes}
                    lastFreeRenewal={freeRenewalParameters.lastActivationTimeIso}
                />
            </Text>
        </div>
    );
};

export default translation(FreeProlongationTimer);
