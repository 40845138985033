import { useState } from 'react';
import { useDispatch } from 'react-redux';

import modalResponseWithMessageSubmitButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/modal_response_with_message_submit_button_click';
import {
    Action,
    BottomSheet,
    Button,
    Modal,
    NavigationBar,
    Text,
    TextArea,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import archiveVacancyApplicantsFeedbackSuccess from 'src/components/Notifications/ArchiveVacancyApplicantsFeedbackSuccess';
import defaultError from 'src/components/Notifications/DefaultError';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { updateManagerVacancyNegotiationsNotProcessedResponsesCount } from 'src/models/employerVacancies/managerVacancies';
import fetcher from 'src/utils/fetcher';

import styles from './response-with-message.less';

const SEND_MESSAGES = '/shards/employer/vacancies/archive_vacancy_applicants_feedback';
interface MessageType {
    message: string;
    vacancyIds: number[];
    disableChatForApplicant?: boolean;
}

declare global {
    interface FetcherPostApi {
        [SEND_MESSAGES]: {
            body: MessageType;
            response: void;
            queryParams: void;
        };
    }
}

interface ResponseWithMessageProps {
    isVisible: boolean;
    vacancyId: number;
    notProcessedResponses: number;
    onClose: () => void;
}

const TrlKeys = {
    messageTemplate: 'employer.vacancy.responseWithMessage.modal.messageTemplate',
    title: 'employer.vacancy.responseWithMessage.modal.title',
    description: 'employer.vacancy.responseWithMessage.modal.description',
    hint: 'employer.vacancy.responseWithMessage.modal.hint',
    candidatesOne: 'employer.vacancy.responseWithMessage.modal.candidates.one',
    candidatesMany: 'employer.vacancy.responseWithMessage.modal.candidates.many',
    sendMessage: 'employer.vacancy.responseWithMessage.modal.sendMessage',
    placeholder: 'employer.vacancy.responseWithMessage.modal.placeholder',
};

const ResponseWithMessage: TranslatedComponent<ResponseWithMessageProps> = ({
    isVisible,
    vacancyId,
    notProcessedResponses,
    onClose,
    trls,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const employerManager = useSelector((state) => state.employerManager);
    const [message, setMessage] = useState(
        format(trls[TrlKeys.messageTemplate], {
            '{0}': employerManager ? `${employerManager.lastName} ${employerManager.firstName}` : '',
        })
    );

    const sendMessages = async () => {
        try {
            await fetcher.post(SEND_MESSAGES, {
                message,
                vacancyIds: [vacancyId],
                disableChatForApplicant: false,
            });
            modalResponseWithMessageSubmitButtonClick({ vacancyId: vacancyId.toString() });
            dispatch(updateManagerVacancyNegotiationsNotProcessedResponsesCount({ vacancyId }));
            addNotification(archiveVacancyApplicantsFeedbackSuccess);

            onClose();
        } catch (_) {
            addNotification(defaultError);
        }
    };

    const modalContent = (
        <>
            <TextArea
                placeholder={trls[TrlKeys.placeholder]}
                elevatePlaceholder
                layout="hug"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
            />
            <VSpacing default={4} />
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.hint]}
            </Text>
        </>
    );

    const candidatesText = notProcessedResponses === 1 ? TrlKeys.candidatesOne : TrlKeys.candidatesMany;
    const cadidatesCountText = (
        <Text typography="label-3-regular" style="secondary">
            {format(trls[candidatesText], { '{0}': notProcessedResponses })}
        </Text>
    );
    const modalButtons = (
        <Button mode="primary" style="accent" onClick={sendMessages}>
            {trls[TrlKeys.sendMessage]}
        </Button>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
                visible={isVisible}
                title={trls[TrlKeys.title]}
                titleSize="medium"
                titleDescription={trls[TrlKeys.description]}
                titleDescriptionStyle="secondary"
                footer={
                    <ActionBar
                        type="modal"
                        additionalContent={<div className={styles.textModal}>{cadidatesCountText}</div>}
                        primaryActions={modalButtons}
                    />
                }
                onClose={onClose}
            >
                {modalContent}
            </Modal>
            <BottomSheet
                visible={isVisible}
                header={
                    <>
                        <NavigationBar
                            title={trls[TrlKeys.title]}
                            subtitle={trls[TrlKeys.description]}
                            right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                        />
                    </>
                }
                footer={
                    <ActionBar
                        type="mobile"
                        showDivider
                        additionalContent={cadidatesCountText}
                        primaryActions={modalButtons}
                    />
                }
                onClose={onClose}
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(ResponseWithMessage);
