import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { Purchase } from 'src/models/prolongateInfo';

import VacancyProlongateErrorNotification from 'src/components/VacancyModals/Prolongation/VacancyProlongateErrorNotification';
import prolongateAndBuyVacancy from 'src/components/VacancyModals/Prolongation/prolongateAndBuyVacancy';

interface ProlongateAndBuyButtonProps {
    isTimerVisible: boolean;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    additionalAnalyticsParams?: Record<string, string>;
}

const TrlKeys = {
    prolongateAndBuy: 'vacancy.prolongate.submit.prolongateAndBuy',
};

const ProlongateAndBuyButton: TranslatedComponent<ProlongateAndBuyButtonProps> = ({
    isTimerVisible,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    additionalAnalyticsParams = {},
    trls,
}) => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const availableToPurchaseIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds
    );
    const products = useSelectorNonNullable(
        ({ prolongateInfo }) => (prolongateInfo.availableToPurchase.purchase as Purchase).products
    );

    const { addNotification } = useNotification();

    const dispatch = useDispatch();
    const push = usePush();

    const prolongateAndBuy = async () => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick(
            'modal_prolongation_prolongate_and_buy_button',
            {
                ...additionalAnalyticsParams,
            },
            // Из-за большого кол-ва вакансий используем body, а не query параметры
            {
                availableToFreeProlongateIds,
                availableToSpendIds,
                availableToPurchaseIds,
            }
        );

        startLoading();
        try {
            await dispatch(
                prolongateAndBuyVacancy({
                    errorNotification: () =>
                        addNotification(VacancyProlongateErrorNotification, { props: { isMultiple: true } }),
                    push,
                    availableToSpendVacancyIds: availableToSpendIds,
                    freeRenewalVacancyIds: availableToFreeProlongateIds,
                    products,
                    purchaseParams: {
                        source: 'prolongatePopup',
                    },
                    vacancyIdsToBuy: availableToPurchaseIds,
                })
            );
        } catch (ignore) {
            console.error(ignore);
            finishLoading();
            handleCloseModal();
        }
    };

    return (
        <Button
            mode={isTimerVisible ? 'secondary' : 'primary'}
            style="accent"
            size="medium"
            loading={loading}
            stretched
            data-qa="prolongate-and-buy-button"
            onClick={prolongateAndBuy}
        >
            {trls[TrlKeys.prolongateAndBuy]}
        </Button>
    );
};

export default translation(ProlongateAndBuyButton);
