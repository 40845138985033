import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './total-price.less';

interface TotalPriceProps {
    currency?: CurrencyType;
    totalPrice?: number;
}

const TrlKeys = {
    total: 'employer.vacancyRisePage.cart.total',
};

// Используется кастомное решение, т.к. FormatMoney игнорируется в footer BottomSheet
export const FormatTotalPrice: FC<TotalPriceProps> = ({ currency = CurrencyType.RUR, totalPrice = 0 }) => (
    <Text typography="subtitle-1-semibold">
        {Strings.formatCostInCents(totalPrice, NON_BREAKING_SPACE)}
        {NON_BREAKING_SPACE}
        <Currency value={currency} />
    </Text>
);

const TotalPrice: TranslatedComponent<TotalPriceProps> = ({ currency, totalPrice, trls }) => (
    <div className={styles.total}>
        <Text typography="label-3-regular">{trls[TrlKeys.total]}</Text>
        <FormatTotalPrice currency={currency} totalPrice={totalPrice} />
    </div>
);

export default translation(TotalPrice);
