export const ADD_MODAL = 'addModal';
export const CLOSE_MODAL = 'closeModal';

export const MODAL_VIDEOINTERVIEW = 'video-interview';
export const MODAL_PROLONGATE = 'prolongate';
export const MODAL_ARCHIVE = 'archive';
export const MODAL_UPGRADE = 'upgrade';
export const MODAL_TRANSFER = 'transfer';
export const MODAL_AUTO_PROLONG = 'auto-prolong';
export const MODAL_REMOVE_AUTO_UPDATE = 'remove-auto-update';
export const MODAL_UPDATES_SCHEDULE = 'update-schedule';
export const MODAL_AUTO_PUBLICATION_DISABLE = 'disable-auto-publication';
export const MODAL_AUTO_PUBLICATION_PREVIEW = 'preview-auto-publication';
export const MODAL_BUY_OPTION = 'buy-vacancy-option';
export const MODAL_HIRING_PLAN = 'hiring-plan';
export const MODAL_TRANSFER_DRAFTS = 'transfer-drafts';
export const MODAL_TRANSFER_ARCHIVED_VACANCIES = 'transfer-archived-vacancies';
export const MODAL_VACANCY_ADVICE = 'vacancy-advice';
export const MODAL_CANCEL_UNPREMODERATED_VACANCY = 'modal-cancel-unpremoderated-vacancy';
export const MODAL_UNPREMODERATED_VACANCY_PREVIEW = 'unpremoderated-vacancy-preview';
export const MODAL_RESPONSE_WITH_MESSAGE = 'response-with-message';
export const MODAL_VACANCY_SHARE = 'vacancy-share';

export const MODAL_FADING_TIME = 150;
