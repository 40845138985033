import { generatePath } from 'react-router';

import paths from 'src/app/routePaths';
import { MarketRange } from 'src/models/canvacVacancySalaryData';
import {
    VacancyAdvice,
    VacancyAdviceSeverity,
    VacancyAdviceWithStatistics,
} from 'src/models/vacancy/vacancyAdvices.types';
import { COLON, EN_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import formatToInt from 'src/utils/formatToInt';
import { isSafariUserAgent } from 'src/utils/isSafariUserAgent';

import {
    ADVICE_TO_ANCHOR,
    NO_PERCENT,
    SALARY_VACANCY_ADVICES,
    VacancyAdviceGroup,
    VacancyAdviceToGroup,
} from 'src/components/VacancyModals/VacancyAdviceModal/constants';

const sortAdviceByGroup = (advices: VacancyAdviceWithStatistics[]): VacancyAdviceWithStatistics[] => {
    const adviceImportant: VacancyAdviceWithStatistics[] = [];
    const advicePaid: VacancyAdviceWithStatistics[] = [];
    const adviceFree: VacancyAdviceWithStatistics[] = [];

    for (const item of advices) {
        switch (VacancyAdviceToGroup[item.advice]) {
            case VacancyAdviceGroup.Important:
                if (SALARY_VACANCY_ADVICES.includes(item.advice)) {
                    adviceImportant.unshift(item);
                } else {
                    adviceImportant.push(item);
                }
                break;
            case VacancyAdviceGroup.Paid:
                advicePaid.push(item);
                break;
            case VacancyAdviceGroup.Free:
                adviceFree.push(item);
                break;
        }
    }

    return [...adviceImportant, ...advicePaid, ...adviceFree];
};

export const sortBySeverity = (adviceWithStatistics: VacancyAdviceWithStatistics[]): VacancyAdviceWithStatistics[] => {
    const majorAdvices: VacancyAdviceWithStatistics[] = [];
    const minorAdvices: VacancyAdviceWithStatistics[] = [];

    adviceWithStatistics.forEach((advice) => {
        if (advice.severity === VacancyAdviceSeverity.Major) {
            majorAdvices.push(advice);
        } else {
            minorAdvices.push(advice);
        }
    });

    return [...sortAdviceByGroup(majorAdvices), ...sortAdviceByGroup(minorAdvices)];
};

export const getLinkUrl = (adviceItem: VacancyAdvice, vacancyId: number): string => {
    switch (adviceItem) {
        case VacancyAdvice.UseSearchTop:
            return `/vacancy/${vacancyId}?runAction=useSearchTop`;
        case VacancyAdvice.RaiseVacancy:
            return '';
        case VacancyAdvice.UseStandardPlus:
            return `/vacancy/${vacancyId}?runAction=upgrade`;
        case VacancyAdvice.UseResumeSearch:
            return (
                `/search/resume?vacancy_id=${vacancyId}&order_by=relevance` +
                '&search_period=365&from=my_vacancy_list_advice_modal'
            );
        case VacancyAdvice.ChangeVacancyName:
            return (
                `/employer/statistics/competitor_analysis/vacancy/${vacancyId}` +
                '?from=employer_vacancies&activeTab=competitorsAnalysis'
            );

        default:
            if (ADVICE_TO_ANCHOR[adviceItem] && !isSafariUserAgent()) {
                return `${generatePath(paths.vacancyEdit, { vacancyId })}?anchor=${ADVICE_TO_ANCHOR[adviceItem]}`;
            }
            return generatePath(paths.vacancyEdit, { vacancyId });
    }
};

export const formatSalary = (marketRange: MarketRange): string =>
    marketRange?.bottom && marketRange?.upper
        ? `${COLON}${NON_BREAKING_SPACE}${String(
              formatToInt(marketRange.bottom)
          )}${NON_BREAKING_SPACE}${EN_DASH}${NON_BREAKING_SPACE}${String(
              formatToInt(marketRange.upper)
          )}${NON_BREAKING_SPACE}`
        : '';

export const noop = (): undefined => undefined;

export const getPercentValue = (delta?: number): number | string => {
    if (!delta || !Number.isFinite(delta)) {
        return NO_PERCENT;
    }

    const percent = (delta * 100) / 2;
    return percent > 100 ? 100 : Math.ceil(percent);
};
