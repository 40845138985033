import { scaleLinear } from 'd3-scale';

import { VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';

import {
    RANGE_TO_PERCENT,
    MIN_CHART_PERCENT,
    MAX_CHART_PERCENT,
} from 'src/components/VacancyView/SalaryMarketRangeChart/constants';
import {
    XAxis,
    SalaryGradient,
    CompensationResult,
    MarketRange,
} from 'src/components/VacancyView/SalaryMarketRangeChart/types';

export const getXAxis = (min: number, max: number, width: number): XAxis =>
    scaleLinear().domain([min, max]).range([MIN_CHART_PERCENT, width]);

// в случае если нет одного из значений ЗП от или до, нужно дополнить график на 5% с градиентом
const PERCENT_OFFSET = 5;
const PERCENT_LOWER_OF_RANGE = 4;
const PERCENT_HIGHER_OF_RANGE = 96;

const PERCENT_LOWER_OF_RANGE_DOT = 2;
const PERCENT_HIGHER_OF_RANGE_DOT = 98;

export const useChartData = (
    marketRange: MarketRange,
    compensation: Partial<VacancyCompensation>
): CompensationResult => {
    let gradient = null;
    let salaryFrom = 0;
    let salaryTo = 0;

    const minSalary = Math.min(...marketRange);
    const maxSalary = Math.max(...marketRange);

    const getPercentScale = (
        min: number,
        max: number,
        percentFrom: number,
        percentTo: number,
        salary: number
    ): number => scaleLinear().domain([min, max]).range([percentFrom, percentTo])(salary);

    const getPercentBySalary = (salary: number): number => {
        if (salary < minSalary) {
            return MIN_CHART_PERCENT;
        }
        if (salary > maxSalary) {
            return MAX_CHART_PERCENT;
        }

        for (let i = 0; i < RANGE_TO_PERCENT.length - 1; i++) {
            const percentFrom = RANGE_TO_PERCENT[i];
            const percentTo = RANGE_TO_PERCENT[i + 1];

            const marketSalaryFrom = marketRange[i];
            const marketSalaryTo = marketRange[i + 1];

            if (salary >= marketSalaryFrom && salary <= marketSalaryTo) {
                return getPercentScale(marketSalaryFrom, marketSalaryTo, percentFrom, percentTo, salary);
            }
        }
        return MIN_CHART_PERCENT;
    };

    const isLowerMarketRangeDot = compensation?.to && compensation.to < minSalary;
    const isHigherMarketRangeDot =
        (compensation?.to && compensation.to > maxSalary && !compensation?.from) ||
        (compensation?.from && compensation.from > maxSalary);
    // точка
    if (isLowerMarketRangeDot || isHigherMarketRangeDot) {
        return {
            from: isLowerMarketRangeDot ? PERCENT_LOWER_OF_RANGE_DOT : PERCENT_HIGHER_OF_RANGE_DOT,
            to: isLowerMarketRangeDot ? PERCENT_LOWER_OF_RANGE_DOT : PERCENT_HIGHER_OF_RANGE_DOT,
            gradient,
            isOutOfRange: true,
        };
    }
    // from ниже или выше рынка, to не указана - градиент
    // градиент
    if (!compensation?.to && compensation?.from && (compensation.from < minSalary || compensation.from > maxSalary)) {
        return {
            from: compensation.from < minSalary ? MIN_CHART_PERCENT : PERCENT_HIGHER_OF_RANGE,
            to: compensation.from < minSalary ? PERCENT_LOWER_OF_RANGE : MAX_CHART_PERCENT,
            gradient: compensation.from < minSalary ? SalaryGradient.In : SalaryGradient.Out,
        };
    }

    if (compensation?.from && !compensation?.to) {
        gradient = SalaryGradient.In;
        salaryFrom = getPercentBySalary(compensation?.from);
        salaryTo = salaryFrom + PERCENT_OFFSET;
    }

    if (compensation?.to && !compensation?.from) {
        gradient = SalaryGradient.Out;
        salaryTo = getPercentBySalary(compensation?.to);
        salaryFrom = salaryTo - PERCENT_OFFSET;
    }

    if (compensation?.from && compensation?.to) {
        salaryFrom = getPercentBySalary(compensation?.from);
        salaryTo = getPercentBySalary(compensation?.to);
    }

    return {
        from: Math.round(salaryFrom),
        to: Math.round(salaryTo),
        gradient,
    };
};
