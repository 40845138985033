import { Dispatch, FC, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
// tempexp_34940_next_line
import { Snackbar } from '@hh.ru/magritte-ui';
// tempexp_34940_next_line
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { useNotification } from 'src/components/Notifications/Provider';
import { useVacancyAdvice } from 'src/components/VacancyCreate/Content/hooks/useVacancyAdvice';
import { AfterSuccessAction } from 'src/components/VacancyModals/Prolongation';
import { ADD_MODAL, MODAL_PROLONGATE } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
// tempexp_34940_next_line
import useOnOffState from 'src/hooks/useOnOffState';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
// tempexp_34940_next_line
import useRunEmployerVacancyAction from 'src/hooks/useRunEmployerVacancyAction';
import { useSelector } from 'src/hooks/useSelector';
import { setRiseErrorStatus, resetRiseValues } from 'src/models/employerVacanciesRise';
import fetcher from 'src/utils/fetcher';

import FreeRenewalTip from 'src/components/VacancyActions/FreeRenewalTip';
import vacancyActionErrorNotification from 'src/components/VacancyActions/VacancyActionError';
import fetchProlongateInfo from 'src/components/VacancyActions/fetchProlongateInfo';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import { validateResponse } from 'src/components/VacancyActions/risePageUtils';

interface ProlongateActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    afterSuccessAction: AfterSuccessAction;
    dispatchModal: Dispatch<AnyAction>;
    onClose?: VoidFunction;
    displayInOwnColumn?: boolean;
    vacanciesIds: number[];
    freeRenewalParameters?: {
        lastActivationTimeIso: string;
        intervalMinutes: string;
    };
    onClickCallback?: () => void;
}

const REGULAR_RISE_INFO_URL = '/shards/employer/vacancies/auto_update/info';

declare global {
    interface FetcherPutApi {
        [REGULAR_RISE_INFO_URL]: {
            queryParams: void;
            body: {
                vacancyId: number[];
            };
            response: {
                forbidden: {
                    vacancies: Record<string, Record<string, string>>;
                };
            };
        };
    }
}

const TrlKeys = {
    prolongate: 'vacancy.action.renewal',
    // tempexp_34940_start
    actionTitle: 'employer.vacancyRisePage.riseOne',
    snackContent: 'vacancy.prolongate.vacancyAdviceSnack',
    // tempexp_34940_end
};
// tempexp_34940_start
const sendEventElementShownSnackAdvice = (vacancyId: number) =>
    Analytics.sendHHEvent('element_shown', {
        elementName: 'snack_advice_prolongation',
        vacancyId,
    });

const sendEventOpenModalFromSnackAdvice = (vacancyId: number) =>
    Analytics.sendHHEventButtonClick('prolongation_from_snack_advice', {
        vacancyId,
    });
// tempexp_34940_start

const ProlongateAction: TranslatedComponent<ProlongateActionProps> = ({
    actionType,
    additionalAnalyticsParams,
    afterSuccessAction,
    Component,
    dispatchModal,
    displayInOwnColumn,
    freeRenewalParameters,
    icon,
    onClose,
    triggerType,
    trls,
    vacanciesIds,
    onClickCallback,
}) => {
    const isZpPlatform = useIsZarplataPlatform();

    const permissions = useSelector(({ permissions }) => permissions);
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const activatorRef = useRef(null);

    const breakpoint = useBreakpoint();
    const canShowHoverTip = [Breakpoint.M, Breakpoint.L].includes(breakpoint);

    const { lastActivationTimeIso, intervalMinutes } = freeRenewalParameters || {
        lastActivationTimeIso: null,
        intervalMinutes: null,
    };
    const isFreeRenewalAvailable = lastActivationTimeIso && intervalMinutes;

    const openModal = useCallback(
        (showRegularButton: boolean) => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_PROLONGATE,
                    data: {
                        afterSuccessAction,
                        freeRenewalParameters: {
                            lastActivationTimeIso,
                            intervalMinutes,
                        },
                        showRegularButton,
                        vacanciesIds,
                        additionalAnalyticsParams,
                    },
                },
            });
            onClose && onClose();
        },
        [
            dispatchModal,
            afterSuccessAction,
            lastActivationTimeIso,
            intervalMinutes,
            vacanciesIds,
            additionalAnalyticsParams,
            onClose,
        ]
    );

    const loadProlongateInfo = useCallback(() => {
        setLoading(true);

        // Если выбрать сначала вакансию с ошибкой, не закрывать снекбар, а затем сразу открыть вакансию без ошибки,
        // то без этой строчки будет открыта и модалка, и снекбар
        dispatch(resetRiseValues());

        return dispatch(fetchProlongateInfo({ vacanciesIds })).then(
            (prolongateInfo) => {
                setLoading(false);

                const error = validateResponse(trls, prolongateInfo.unavailable, vacanciesIds);
                if (error) {
                    dispatch(setRiseErrorStatus({ message: error }));
                    return;
                }

                if (isZpPlatform) {
                    openModal(false);
                } else {
                    let showRegularButton = false;
                    fetcher
                        .put(REGULAR_RISE_INFO_URL, {
                            vacancyId: vacanciesIds,
                        })
                        .then(({ data }) => {
                            showRegularButton = Object.keys(data.forbidden.vacancies).length !== vacanciesIds.length;
                        }, console.error)
                        .finally(() => {
                            openModal(showRegularButton);
                        });
                }
            },
            () => {
                addNotification(vacancyActionErrorNotification);
                setLoading(false);
            }
        );
    }, [dispatch, vacanciesIds, trls, isZpPlatform, openModal, addNotification]);

    const onTrigger = () => {
        onClickCallback?.();
        return loadProlongateInfo();
    };
    // tempexp_34940_start
    const {
        isTestGroupPlaceA,
        isTestGroupPlaceB,
        isTestGroupPlaceC,
        isVacancyAdvicePlaceAllowedToday,
        resetTrigger,
        triggerOnAdviceFromSnack,
        setNotAllowedVacancyAdvicePlaceForToday,
    } = useVacancyAdvice();
    const [isSnackAdvice, showSnackAdvice, hideSnackAdvice] = useOnOffState(false);
    useRunEmployerVacancyAction('prolongate_advice', () => {
        if (isTestGroupPlaceC) {
            sendEventElementShownSnackAdvice(vacanciesIds[0]);
            showSnackAdvice();
        }
    });

    // будем вызывать этот колбэк а не `onTrigger` каждый раз когда разрешен показ совета в ноте
    const onTriggerAdvice = (isFromRunAction?: boolean) => {
        const currentSearchParams = new URLSearchParams(location.search);
        // пришел из редиректа совета
        const isFromAdvice = currentSearchParams.get('advice') === 'true' && isFromRunAction;
        // убераем из url свидетельство если оно было что пришло из экспа с советами
        resetTrigger();

        if (!isFromAdvice || isTestGroupPlaceA || isTestGroupPlaceB) {
            onClickCallback?.();
            return loadProlongateInfo();
        }

        return undefined;
    };
    // tempexp_34940_end
    return (
        <>
            {isSnackAdvice && (
                <Snackbar
                    autohideTime={0}
                    addon={<CheckCircleFilledSize24 initial="positive" />}
                    actionLabel={trls[TrlKeys.actionTitle]}
                    onAction={() => {
                        sendEventOpenModalFromSnackAdvice(vacanciesIds[0]);
                        triggerOnAdviceFromSnack();
                        onTrigger().then(hideSnackAdvice).catch(console.error);
                    }}
                    showClose
                    onClose={() => {
                        setNotAllowedVacancyAdvicePlaceForToday();
                        hideSnackAdvice();
                    }}
                >
                    {trls[TrlKeys.snackContent]}
                </Snackbar>
            )}
            <Component
                actionType={actionType}
                additionalAnalyticsParams={additionalAnalyticsParams}
                batchLength={vacanciesIds.length}
                displayInOwnColumn={displayInOwnColumn}
                icon={icon}
                isLoading={isLoading}
                isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
                onTrigger={isVacancyAdvicePlaceAllowedToday ? onTriggerAdvice : onTrigger}
                triggerName="prolongate"
                triggerType={triggerType}
            >
                {isFreeRenewalAvailable && canShowHoverTip ? (
                    <HoverTip
                        placement={TipPlacement.Bottom}
                        render={() => (
                            <FreeRenewalTip
                                lastFreeRenewal={lastActivationTimeIso}
                                freeRenewalInterval={intervalMinutes}
                            />
                        )}
                        host={!process.env.SSR ? document.body : null}
                        activatorRef={activatorRef}
                    >
                        <span ref={activatorRef}>{trls[TrlKeys.prolongate]}</span>
                    </HoverTip>
                ) : (
                    trls[TrlKeys.prolongate]
                )}
            </Component>
        </>
    );
};

export default translation(ProlongateAction);
