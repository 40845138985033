import PropTypes from 'prop-types';

import translation from 'src/components/translation';

const LinkQuestionnaire = ({ trls }) => trls[LinkQuestionnaire.trls.message];
LinkQuestionnaire.propTypes = { trls: PropTypes.object };
LinkQuestionnaire.trls = { message: 'employer.videoInterview.linkQuestionnaire' };
const linkQuestionnaire = {
    Element: translation(LinkQuestionnaire),
    kind: 'ok',
    autoClose: true,
};
const LINK_QUESTIONNAIRE = 'linkQuestionnaire';

const UnlinkQuestionnaire = ({ trls }) => trls[UnlinkQuestionnaire.trls.message];
UnlinkQuestionnaire.propTypes = { trls: PropTypes.object };
UnlinkQuestionnaire.trls = { message: 'employer.videoInterview.unlinkQuestionnaire' };
const unlinkQuestionnaire = {
    Element: translation(UnlinkQuestionnaire),
    kind: 'ok',
    autoClose: true,
};
const UNLINK_QUESTIONNAIRE = 'unlinkQuestionnaire';

const DeleteQuestionnaire = ({ trls }) => trls[DeleteQuestionnaire.trls.message];
DeleteQuestionnaire.propTypes = { trls: PropTypes.object };
DeleteQuestionnaire.trls = { message: 'employer.videoInterview.deleteQuestionnaire' };
const deleteQuestionnaire = {
    Element: translation(DeleteQuestionnaire),
    kind: 'ok',
    autoClose: true,
};
const DELETE_QUESTIONNAIRE = 'deleteQuestionnaire';

const EditQuestionnaire = ({ trls }) => trls[EditQuestionnaire.trls.message];
EditQuestionnaire.propTypes = { trls: PropTypes.object };
EditQuestionnaire.trls = { message: 'employer.videoInterview.editQuestionnaire' };
const editQuestionnaire = {
    Element: translation(EditQuestionnaire),
    kind: 'ok',
    autoClose: true,
};
const EDIT_QUESTIONNAIRE = 'editQuestionnaire';

const AddQuestionnaire = ({ trls }) => trls[AddQuestionnaire.trls.message];
AddQuestionnaire.propTypes = { trls: PropTypes.object };
AddQuestionnaire.trls = { message: 'employer.videoInterview.addQuestionnaire' };
const addQuestionnaire = {
    Element: translation(AddQuestionnaire),
    kind: 'ok',
    autoClose: true,
};
const ADD_QUESTIONNAIRE = 'addQuestionnaire';

export const VIDEO_INTERVIEW_NOTIFICATIONS = {
    [EDIT_QUESTIONNAIRE]: editQuestionnaire,
    [LINK_QUESTIONNAIRE]: linkQuestionnaire,
    [UNLINK_QUESTIONNAIRE]: unlinkQuestionnaire,
    [DELETE_QUESTIONNAIRE]: deleteQuestionnaire,
    [ADD_QUESTIONNAIRE]: addQuestionnaire,
};
