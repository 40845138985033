import { FC, ComponentType } from 'react';

import ChartContainer, { ChartContainerProps } from 'src/components/VacancyView/SalaryMarketRangeChart/ChartContainer';
import { ChartProps } from 'src/components/VacancyView/SalaryMarketRangeChart/types';

type WithChartContainerProps<P> = P & ChartContainerProps;

const withChartContainer =
    <P extends ChartProps>(Component: ComponentType<P>): FC<WithChartContainerProps<P>> =>
    // eslint-disable-next-line react/display-name
    (props: WithChartContainerProps<P>) => (
        <ChartContainer height={props.height} hOffset={props.hOffset}>
            <Component {...props} />
        </ChartContainer>
    );

export default withChartContainer;
