import {
    Action,
    BottomSheet,
    Button,
    Modal,
    NavigationBar,
    VSpacing,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyRow from 'src/components/EmployerVacancies/VacancyRow';
import { getProlongationError } from 'src/components/VacancyActions/risePageUtils';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './unavailable-details.less';

interface UnavailableDetailsProps {
    visible: boolean;
    handleCloseDetailsModal: () => void;
}

const TrlKeys = {
    notPossibleToRise: 'employer.vacancyRisePage.notPossibleToRise',
    back: 'Back',
};

const UnavailableDetails: TranslatedComponent<UnavailableDetailsProps> = ({
    visible,
    handleCloseDetailsModal,
    trls,
}) => {
    const unavailable = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable);
    const { failVacancies = [] } = unavailable;
    const { isMobile } = useBreakpoint();
    const title = trls[TrlKeys.notPossibleToRise];

    const content = (
        <div data-qa="prolongate-unavailable">
            {failVacancies.map(({ reason, vacancy }) => {
                const { errorText } = getProlongationError(trls, reason);

                return (
                    <div
                        className={styles.rowWrapper}
                        data-qa={`prolongate-unavailable-reason_${reason}`}
                        key={vacancy.vacancyId}
                    >
                        <VacancyRow description={errorText} {...vacancy} />
                    </div>
                );
            })}
        </div>
    );

    return isMobile ? (
        <BottomSheet
            header={<NavigationBar title={title} showDivider="always" />}
            visible={visible}
            footer={<VSpacing default={16} />}
            onClose={handleCloseDetailsModal}
        >
            {content}
        </BottomSheet>
    ) : (
        <Modal
            visible={visible}
            size="small"
            title={title}
            titleSize="medium"
            actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={handleCloseDetailsModal} />}
            footer={
                <ActionBar
                    type="modal-vertical"
                    primaryActions={
                        <Button mode="secondary" style="accent" size="medium" onClick={handleCloseDetailsModal}>
                            {trls[TrlKeys.back]}
                        </Button>
                    }
                />
            }
            onClose={handleCloseDetailsModal}
        >
            {content}
        </Modal>
    );
};

export default translation(UnavailableDetails);
