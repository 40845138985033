// tempexp_34940_file
import { FC } from 'react';

import { Cell, VSpacing } from '@hh.ru/magritte-ui';

import styles from './loader-advice.less';

const LoaderAdviceContent: FC = () => {
    return (
        <div>
            <VSpacing default={12} />
            <div className={styles.content}>
                <Cell.Skeleton height={40} width={40} borderRadius={12} loading />
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <Cell.Skeleton height={14} borderRadius={4} loading />
                    </div>

                    <VSpacing default={12} />
                    <div className={styles.right}>
                        <Cell.Skeleton height={14} borderRadius={4} loading />
                    </div>

                    <VSpacing default={12} />

                    <div className={styles.right}>
                        <Cell.Skeleton height={14} borderRadius={4} loading />
                    </div>
                </div>
                <div>
                    <Cell.Skeleton height={14} width={48} borderRadius={4} loading />
                    <VSpacing default={12} />
                    <Cell.Skeleton height={14} width={48} borderRadius={4} loading />
                </div>
            </div>
            <VSpacing default={12} />
        </div>
    );
};

export default LoaderAdviceContent;
