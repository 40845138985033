import { Button, ButtonMode, ButtonSize, ButtonStyle, useBreakpoint } from '@hh.ru/magritte-ui';
import { MinusCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    completion: 'employer.unpremoderated.vacancyCompletion',
};

interface CompletionButtonProps {
    handleClick: () => void;
}

const CompletionButton: TranslatedComponent<CompletionButtonProps> = ({ trls, handleClick }) => {
    const { isMobile } = useBreakpoint();

    const buttonProps = isMobile
        ? {
              icon: <MinusCircleOutlinedSize24 />,
              mode: 'tertiary' as ButtonMode,
              style: 'neutral' as ButtonStyle,
              size: 'large' as ButtonSize,
          }
        : {
              mode: 'secondary' as ButtonMode,
              style: 'accent' as ButtonStyle,
              size: 'small' as ButtonSize,
          };

    return (
        <Button onClick={handleClick} stretched={true} {...buttonProps}>
            {trls[TrlKeys.completion]}
        </Button>
    );
};

export default translation(CompletionButton);
