import { Action, BottomSheet, NavigationBar, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface ProlongateInfoBottomSheetProps {
    visible: boolean;
    onClose: () => void;
}

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.infoTitle',
    infoHover: {
        0: 'employer.myVacancies.prolongate.info.hover.0',
        1: 'employer.myVacancies.prolongate.info.hover.1',
        2: 'employer.myVacancies.prolongate.info.hover.2',
    },
};

const ProlongateInfoBottomSheet: TranslatedComponent<ProlongateInfoBottomSheetProps> = ({ visible, onClose, trls }) => {
    return (
        <BottomSheet
            visible={visible}
            header={
                <NavigationBar
                    title={trls[TrlKeys.title]}
                    left={<Action icon={ChevronLeftOutlinedSize24} onClick={onClose} />}
                    showDivider="always"
                />
            }
            onClose={onClose}
        >
            <Text typography="label-2-regular">
                {trls[TrlKeys.infoHover[0]]}
                <VSpacing default={8} />
                {trls[TrlKeys.infoHover[1]]}
                <VSpacing default={8} />
                {trls[TrlKeys.infoHover[2]]}
            </Text>
        </BottomSheet>
    );
};

export default translation(ProlongateInfoBottomSheet);
