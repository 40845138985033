import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './unavailable.less';

interface UnavailableProps {
    handleClickMoreDetails: () => void;
}

const TrlKeys = {
    exceptOne: 'employer.vacancyRisePage.banner.exceptOne',
    exceptSome: 'employer.vacancyRisePage.banner.exceptSome',
};

const Unavailable: TranslatedComponent<UnavailableProps> = ({ handleClickMoreDetails, trls }) => {
    const unavailable = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable);
    const { failVacancies = [] } = unavailable;

    if (!failVacancies.length) {
        return null;
    }

    return (
        <>
            <Card stretched style="warning" borderRadius={16} padding={16} actionCard onClick={handleClickMoreDetails}>
                <div className={styles.wrapper}>
                    <Text>{failVacancies.length === 1 ? trls[TrlKeys.exceptOne] : trls[TrlKeys.exceptSome]}</Text>
                    <ChevronRightOutlinedSize24 />
                </div>
            </Card>
            <VSpacing default={16} />
        </>
    );
};

export default translation(Unavailable);
