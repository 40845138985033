import { useBreakpoint, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './market-range-text.less';

const TrlKeys = {
    ranges: {
        offMarket: 'vacancy.salaryMarketRangeChart.market.range.offMarket',
        inMarket: 'vacancy.salaryMarketRangeChart.market.range.inMarket',
        aboveMarket: 'vacancy.salaryMarketRangeChart.market.range.aboveMarket',
    },
};

const MarketRangeText: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const textTypography = isMobile ? 'label-3-regular' : 'label-2-regular';

    return (
        <div className={styles.marketRangeText}>
            {Object.keys(TrlKeys.ranges).map((key) => (
                <Text key={key} typography={textTypography}>
                    {trls[TrlKeys.ranges[key]]}
                </Text>
            ))}
        </div>
    );
};

export default translation(MarketRangeText);
