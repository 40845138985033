import { FC, PropsWithChildren } from 'react';

import { Button, BottomSheet, BottomSheetFooter, NavigationBar } from '@hh.ru/magritte-ui';

import { noop } from 'src/components/VacancyModals/VacancyAdviceModal/utils';

import styles from './mobile-tip.less';

interface MobileTipProps extends PropsWithChildren {
    title: string;
    closeText: string;
    visible: boolean;
    onAppear?: () => void;
    onClose: () => void;
}

const MobileTip: FC<MobileTipProps> = ({ title, children, visible, onAppear = noop, onClose, closeText }) => {
    return (
        <BottomSheet
            header={<NavigationBar title={title} showDivider="always" />}
            visible={visible}
            onClose={onClose}
            onAppear={onAppear}
            footer={
                <BottomSheetFooter>
                    <Button mode="secondary" style="neutral" onClick={onClose}>
                        {closeText}
                    </Button>
                </BottomSheetFooter>
            }
        >
            <div className={styles.mobileTipContent}>{children}</div>
        </BottomSheet>
    );
};

export default MobileTip;
