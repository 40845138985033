import { Push } from '@hh.ru/redux-spa-middleware';

import { PublicationProductTypeWithTrls } from 'src/models/prolongateInfo';
import fetcher from 'src/utils/fetcher';

const PROLONGATE_AND_BUY_VACANCY_URL = '/shards/employer/vacancies/prolongate/prolongate_and_buy';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_AND_BUY_VACANCY_URL]: {
            queryParams: void;
            body: {
                availableToSpendVacancyIds?: number[];
                freeRenewalVacancyIds?: number[];
                products: PublicationProductTypeWithTrls[];
                purchaseParams: {
                    source: string;
                };
                vacancyIdsToBuy: number[];
            };
            response: { purchaseUrl: string };
        };
    }
}

const prolongateAndBuyVacancy =
    ({
        errorNotification,
        push,
        availableToSpendVacancyIds,
        freeRenewalVacancyIds,
        products,
        purchaseParams,
        vacancyIdsToBuy,
    }: {
        errorNotification?: () => void;
        push: Push;
        availableToSpendVacancyIds: number[];
        freeRenewalVacancyIds: number[];
        products: PublicationProductTypeWithTrls[];
        purchaseParams: {
            source: string;
        };
        vacancyIdsToBuy: number[];
    }) =>
    async (): Promise<void> => {
        let response;
        try {
            response = await fetcher.post(PROLONGATE_AND_BUY_VACANCY_URL, {
                availableToSpendVacancyIds,
                freeRenewalVacancyIds,
                products,
                purchaseParams,
                vacancyIdsToBuy,
            });
            push(response.data.purchaseUrl);
        } catch (error) {
            errorNotification?.();
            throw error;
        }
    };

export default prolongateAndBuyVacancy;
