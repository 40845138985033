import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import VacancyProlongateErrorNotification from 'src/components/VacancyModals/Prolongation/VacancyProlongateErrorNotification';
import prolongateVacancy from 'src/components/VacancyModals/Prolongation/prolongateVacancy';

interface ProlongateButtonProps {
    isTimerVisible: boolean;
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    additionalAnalyticsParams?: Record<string, string>;
}

const TrlKeys = {
    prolongate: 'vacancy.prolongate.submit.prolongate',
    prolongateFree: 'vacancy.prolongate.submit.prolongateFree',
};

const ProlongateButton: TranslatedComponent<ProlongateButtonProps> = ({
    isTimerVisible,
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    additionalAnalyticsParams = {},
    trls,
}) => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );

    const { addNotification } = useNotification();

    const prolongate = async () => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick(
            'modal_prolongation_button',
            {
                ...additionalAnalyticsParams,
            },
            // Из-за большого кол-ва вакансий используем body, а не query параметры
            {
                vacanciesIds: [...availableToSpendIds, ...availableToFreeProlongateIds],
            }
        );

        startLoading();
        try {
            const queryParams = await prolongateVacancy({
                errorNotification: () =>
                    addNotification(VacancyProlongateErrorNotification, {
                        props: { isMultiple: availableToSpendIds.length > 1 },
                    }),
                freeRenewalVacancyIds: availableToFreeProlongateIds,
                vacancyIds: availableToSpendIds,
            });

            if (afterSuccessAction) {
                afterSuccessAction({
                    handleCloseModal,
                    queryParams,
                    vacancyIds: [...availableToSpendIds, ...availableToFreeProlongateIds],
                });

                return;
            }
            // Нельзя написать в finally, т.к. будет закрывать любую модалку, в т.ч. открытую в afterSuccessAction
            handleCloseModal();
        } catch (ignore) {
            console.error(ignore);
            handleCloseModal();
        } finally {
            finishLoading();
        }
    };

    return (
        <Button
            mode={isTimerVisible ? 'secondary' : 'primary'}
            style="accent"
            size="medium"
            loading={loading}
            stretched
            data-qa="prolongate-button"
            onClick={prolongate}
        >
            {!!availableToFreeProlongateIds.length && !availableToSpendIds.length
                ? trls[TrlKeys.prolongateFree]
                : trls[TrlKeys.prolongate]}
        </Button>
    );
};

export default translation(ProlongateButton);
