import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyItem from 'src/components/EmployerVacancies/VacancyItem';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { PublicationCodeAvailableForPurchase } from 'src/models/price/productCodes';
import { getPublicationCode, getPublicationsCount } from 'src/utils/price/products';

import { SelectedSegment } from 'src/components/VacancyModals/Prolongation/SegmentedControl';

interface AvailableToPurchaseProps {
    selectedSegment: SelectedSegment;
}

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.availableToPurchase.title',
};

const AvailableToPurchase: TranslatedComponent<AvailableToPurchaseProps> = ({ selectedSegment, trls }) => {
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);
    const spendLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds.length
    );
    const freeLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds.length
    );
    const availableToUpgrade = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToUpgrade);

    if (!purchase || (!!availableToUpgrade && selectedSegment === 'deduct')) {
        return null;
    }

    return (
        <>
            {(!!spendLength || !!freeLength) && (
                <>
                    {!!spendLength && <VSpacing default={16} />}
                    <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                    <VSpacing default={8} />
                </>
            )}
            {purchase.products.map((product) => {
                const billingCode = getPublicationCode(product) as PublicationCodeAvailableForPurchase;

                return (
                    <VacancyItem
                        key={billingCode + product.regionTrl + product.profRoleGroupsTrls[0]}
                        billingCode={billingCode}
                        currency={product.currency}
                        region={product.regionTrl}
                        profRoleGroup={product.profRoleGroupsTrls[0]}
                        price={product.price}
                        amount={getPublicationsCount(product) ?? 0}
                    />
                );
            })}
        </>
    );
};

export default translation(AvailableToPurchase);
