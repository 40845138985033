import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppStore } from 'src/app/store';
import {
    EntityType,
    updateVacanciesFilteredGroups,
    VacancyFilteredGroup,
} from 'src/models/employerVacancies/managerVacanciesFilteredGroups';
import fetcher from 'src/utils/fetcher';

const VACANCIES_FILTERED_GROUPS_URL = '/shards/employer/vacancies_dashboard/filtered_groups';
const VACANCIES_FILTERED_GROUPS_FOR_DRAFTS_URL = '/shards/employer/vacancies_dashboard/draft_filtered_groups';

declare global {
    interface FetcherGetApi {
        [VACANCIES_FILTERED_GROUPS_URL]: {
            queryParams: void;
            response: { managerVacanciesFilteredGroups: VacancyFilteredGroup[] };
        };
    }
}
declare global {
    interface FetcherGetApi {
        [VACANCIES_FILTERED_GROUPS_FOR_DRAFTS_URL]: {
            queryParams: void;
            response: { managerVacanciesFilteredGroups: VacancyFilteredGroup[] };
        };
    }
}

const entityTypeToVacanciesGroupUrl = {
    vacancy: VACANCIES_FILTERED_GROUPS_URL,
    draft: VACANCIES_FILTERED_GROUPS_FOR_DRAFTS_URL,
} as const;

export default (
        currentGroupId: string,
        entityType: EntityType = 'vacancy'
    ): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> =>
    async (dispatch) => {
        let groupsResponse = null;
        try {
            groupsResponse = await fetcher.get(entityTypeToVacanciesGroupUrl[entityType]);
        } catch (e) {
            console.error(e);
        }

        if (groupsResponse) {
            dispatch(
                updateVacanciesFilteredGroups({
                    groups: groupsResponse.managerVacanciesFilteredGroups,
                    currentGroupId,
                    entityType,
                })
            );
        }
    };
