import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { AppStore } from 'src/app/store';
import { VacanciesFilters } from 'src/models/employerVacancies/vacancy.types';
import { ManagerCluster } from 'src/models/managerCluster';
import fetcher from 'src/utils/fetcher';

import { DispatchFilter } from 'src/components/EmployerVacancies/FilterContext';
import { SET_FILTERS } from 'src/components/EmployerVacancies/filterReducer';
import {
    getAvailableFiltersByClusters,
    intersectFiltersAndAvailableFilters,
    prepareManagerCluster,
} from 'src/components/EmployerVacancies/filters';

const employerManagerAction = makeSetStoreField('managerCluster');

const VACANCIES_CLUSTERS_URL = '/shards/employer/vacancies_dashboard/clusters';

declare global {
    interface FetcherGetApi {
        [VACANCIES_CLUSTERS_URL]: {
            queryParams: { groupId: string | undefined };
            response: ManagerCluster;
        };
    }
}

export default (
        filters: VacanciesFilters,
        dispatchFilter: DispatchFilter,
        groupId: string | undefined
    ): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> =>
    async (dispatch) => {
        let clusters;
        try {
            clusters = await fetcher.get(VACANCIES_CLUSTERS_URL, { params: { groupId } });
        } catch (errror) {
            console.error(errror);
            return filters;
        }

        const managerClusters = prepareManagerCluster(clusters);
        const availableFilters = getAvailableFiltersByClusters(managerClusters);

        const newFilters = intersectFiltersAndAvailableFilters(filters, availableFilters);

        dispatch(employerManagerAction(clusters));
        dispatchFilter({ type: SET_FILTERS, payload: newFilters });

        return newFilters;
    };
