import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyItem from 'src/components/EmployerVacancies/VacancyItem';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.availableToSpend.title',
};

const AvailableToSpend: TranslatedComponent = ({ trls }) => {
    const publications = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToSpend.publications);
    const purchaseLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds.length
    );
    const freeLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds.length
    );

    if (!publications) {
        return null;
    }

    return (
        <>
            {(!!purchaseLength || !!freeLength) && (
                <>
                    <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                    <VSpacing default={8} />
                </>
            )}
            {publications.map((publication, index) => (
                <VacancyItem
                    key={index}
                    billingCode={publication.billingCode}
                    region={publication.regionsTrls.length > 0 ? publication.regionsTrls.join(',') : undefined}
                    profRoleGroup={
                        publication.profRoleGroupsTrls.length > 0 ? publication.profRoleGroupsTrls.join(',') : undefined
                    }
                    amount={publication.count}
                />
            ))}
        </>
    );
};

export default translation(AvailableToSpend);
