import { useCallback, useState } from 'react';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { UnpremoderatedVacancy } from 'src/models/unpremoderatedVacancyList';

import ModalContent from 'src/components/VacancyModals/CancelUnpremoderatedVacancyModal/ModalContent';
import { CancelUnpremoderatedVacancyModalType } from 'src/components/VacancyModals/CancelUnpremoderatedVacancyModal/types';
import { useUnpremoderatedVacanciesActionsFetcher } from 'src/components/VacancyModals/CancelUnpremoderatedVacancyModal/useUnpremoderatedVacanciesActionsFetcher';

interface CancelUnpremoderatedVacancyModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    handleCancel?: () => void;
    moderatorId: number;
    vacancy: UnpremoderatedVacancy;
    type: CancelUnpremoderatedVacancyModalType;
}

const TrlKeys = {
    completion: {
        title: 'employer.unpremoderated.cancel.modal.completion.title',
        submit: 'employer.unpremoderated.cancel.modal.completion.submit',
    },
    discard: {
        title: 'employer.unpremoderated.cancel.modal.discard.title',
        submit: 'employer.unpremoderated.cancel.modal.discard.submit',
    },
    cancel: 'employer.unpremoderated.cancel.modal.cancel',
};

const CancelUnpremoderatedVacancyModal: TranslatedComponent<CancelUnpremoderatedVacancyModalProps> = ({
    handleCloseModal,
    handleCancel,
    isVisible,
    moderatorId,
    vacancy,
    type,
    trls,
}) => {
    const { isMobile } = useBreakpoint();
    const vacancyId = vacancy.id;
    const [value, setValue] = useState('');
    const { isLoading, cancelToDiscardUnpremoderatedVacancy, cancelToCompletionUnpremoderatedVacancy } =
        useUnpremoderatedVacanciesActionsFetcher(moderatorId, vacancyId);

    const handleSubmitClick = async () => {
        if (type === CancelUnpremoderatedVacancyModalType.COMPLETION) {
            await cancelToCompletionUnpremoderatedVacancy(value);
        } else {
            await cancelToDiscardUnpremoderatedVacancy(value);
        }

        handleCloseModal();
    };

    const onCancel = useCallback(() => {
        handleCloseModal();
        handleCancel?.();
    }, [handleCancel, handleCloseModal]);

    const TrlsByType = TrlKeys[type];

    const submitBtn = (
        <Button
            loading={isLoading}
            mode="primary"
            style={type === CancelUnpremoderatedVacancyModalType.COMPLETION ? 'accent' : 'negative'}
            onClick={handleSubmitClick}
            data-qa="unpremoderated-modal-discard-submit"
        >
            {trls[TrlsByType.submit]}
        </Button>
    );
    return (
        <>
            <Modal
                footer={
                    <ActionBar
                        type="modal"
                        primaryActions={submitBtn}
                        secondaryActions={
                            <Button
                                mode="tertiary"
                                style="accent"
                                disabled={isLoading}
                                onClick={onCancel}
                                data-qa="unpremoderated-modal-discard-cancel"
                            >
                                {trls[TrlKeys.cancel]}
                            </Button>
                        }
                    />
                }
                onClose={onCancel}
                visible={isVisible}
                actions={<Action mode="secondary" onClick={onCancel} icon={CrossOutlinedSize24} />}
                title={trls[TrlsByType.title]}
            >
                <ModalContent value={value} onChange={setValue} />
            </Modal>
            {isMobile && (
                <BottomSheet
                    footer={<BottomSheetFooter>{submitBtn}</BottomSheetFooter>}
                    header={
                        <NavigationBar
                            right={<Action icon={CrossOutlinedSize24} onClick={onCancel} />}
                            showDivider="always"
                            title={trls[TrlsByType.title]}
                        />
                    }
                    onClose={onCancel}
                    visible={isVisible}
                >
                    <ModalContent value={value} onChange={setValue} />
                    <VSpacing default={16} />
                </BottomSheet>
            )}
        </>
    );
};

export default translation(CancelUnpremoderatedVacancyModal);
