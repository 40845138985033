import fetcher from 'src/utils/fetcher';

const PROLONGATE_VACANCY_URL = '/shards/employer/vacancies/prolongate';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_VACANCY_URL]: {
            queryParams: void;
            body: {
                freeRenewalVacancyIds?: number[];
                vacancyIds?: number[];
            };
            response: Record<string, number[]>;
        };
    }
}

interface ProlongateVacancy {
    errorNotification?: () => void;
    freeRenewalVacancyIds: number[];
    vacancyIds: number[];
}

const prolongateVacancy = async ({
    errorNotification,
    freeRenewalVacancyIds,
    vacancyIds,
}: ProlongateVacancy): Promise<Record<string, number[]>> => {
    let response;
    try {
        response = await fetcher.post(PROLONGATE_VACANCY_URL, { freeRenewalVacancyIds, vacancyIds });
    } catch (error) {
        errorNotification?.();
        throw error;
    }

    return response.data;
};

export default prolongateVacancy;
