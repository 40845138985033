import { memo, useCallback } from 'react';

import { Reason } from '@hh.ru/analytics-js-events/build/xhh/employer/archive/modal_archive_button_button_click';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Modal,
    NavigationBar,
    Radio,
    Text,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    archive: 'employer.myVacancyes.archivate',
    reason: 'employer.myVacancies.archive.title.reason',
    foundHere: 'employer.myVacancies.archive.reason.foundHere',
    foundNotHere: 'employer.myVacancies.archive.reason.foundNotHere',
    otherReason: 'employer.myVacancies.archive.reason.otherReason',
    warning: 'employer.myVacancies.archive.warning',
    cancel: 'employer.myVacancyes.move.cancel',
    toArchive: 'employer.myVacancies.archive.toArchive',
    error: 'employer.myVacancies.archive.error',
    title: {
        one: 'employer.myVacancies.archive.title.one',
        some: 'employer.myVacancies.archive.title.some',
        many: 'employer.myVacancies.archive.title.many',
    },
    text: {
        one: 'employer.myVacancies.archive.text.one',
        some: 'employer.myVacancies.archive.text.some',
        many: 'employer.myVacancies.archive.text.many',
    },
};
const POSTFIX_NAME = 'foundHere';

const ARCHIVE_REASONS: { name: Reason; qa: string }[] = [
    { name: POSTFIX_NAME, qa: 'vacancies-radio-reason_foundhere' },
    { name: 'foundNotHere', qa: 'vacancies-radio-reason_foundnothere' },
    { name: 'otherReason', qa: 'vacancies-radio-reason_otherreason' },
];

interface ArchiveModalProps {
    visible: boolean;
    vacanciesToArchiveCount: number;
    archivationReasonRequired: boolean;
    archivationReason: string | null;
    archivationReasonError: string | null;
    archivationSubmitError: boolean;
    onSelectArchivationReason: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClose: () => void;
    onSubmit: () => void;
    onCancel?: () => void;
}

const ArchiveModal: TranslatedComponent<ArchiveModalProps> = ({
    trls,
    visible,
    vacanciesToArchiveCount,
    archivationReasonRequired,
    archivationReason,
    archivationReasonError,
    archivationSubmitError,
    onSelectArchivationReason,
    onClose,
    onSubmit,
    onCancel,
}) => {
    const { isMobile } = useBreakpoint();

    const archiveModalTitle = (
        <ConversionNumber
            value={vacanciesToArchiveCount}
            format={(trl) => format(trl, { '%s': vacanciesToArchiveCount })}
            one={trls[archivationReasonRequired ? TrlKeys.reason : TrlKeys.title.one]}
            some={trls[TrlKeys.title.some]}
            many={trls[TrlKeys.title.many]}
            hasValue={false}
        />
    );

    const handleCancel = useCallback(() => {
        onClose();
        onCancel?.();
    }, [onCancel, onClose]);

    const archiveModalContent = (
        <div className={styles.archiveModalContent}>
            <div className={styles.archiveModalReasons}>
                {archivationReasonRequired ? (
                    ARCHIVE_REASONS.map(({ name, qa }) => {
                        const text = trls[TrlKeys[name]];
                        return (
                            <div key={name}>
                                <label className={styles.archiveModalReasonWrapper}>
                                    <Radio
                                        value={name}
                                        checked={name === archivationReason}
                                        invalid={archivationSubmitError}
                                        onChange={onSelectArchivationReason}
                                    />
                                    <Text data-qa={qa} typography="paragraph-3-regular">
                                        {name === POSTFIX_NAME
                                            ? `${text}${NON_BREAKING_SPACE}${window.location.hostname}`
                                            : text}
                                    </Text>
                                </label>
                            </div>
                        );
                    })
                ) : (
                    <ConversionNumber
                        value={vacanciesToArchiveCount}
                        format={(trl) => format(trl, { '%s': vacanciesToArchiveCount })}
                        one={trls[TrlKeys.text.one]}
                        some={trls[TrlKeys.text.some]}
                        many={trls[TrlKeys.text.many]}
                        hasValue={false}
                    />
                )}
            </div>
            {(archivationSubmitError || archivationReasonError) && (
                <Card data-qa="vacancies-archive-modal-submit-error" stretched style="negative" padding={16}>
                    <Text typography="paragraph-3-regular">
                        {archivationSubmitError ? trls[TrlKeys.error] : archivationReasonError}
                    </Text>
                </Card>
            )}
            <Text typography="paragraph-3-regular">{trls[TrlKeys.warning]}</Text>
        </div>
    );

    const archiveModalButtons = (
        <>
            <Button mode="secondary" style="accent" data-qa="vacancies-archivePopup-cancel" onClick={handleCancel}>
                {trls[TrlKeys.cancel]}
            </Button>
            <Button mode="primary" style="accent" data-qa="vacancies-archivePopup-archive" onClick={onSubmit}>
                {trls[TrlKeys.toArchive]}
            </Button>
        </>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={handleCancel} icon={CrossOutlinedSize24} />}
                visible={visible}
                title={archiveModalTitle}
                titleSize="medium"
                footer={<ActionBar primaryActions={archiveModalButtons} />}
                onClose={handleCancel}
                data-qa="vacancies-archive-modal"
            >
                {archiveModalContent}
            </Modal>
            <BottomSheet
                visible={isMobile && visible}
                header={<NavigationBar title={archiveModalTitle} />}
                footer={<BottomSheetFooter>{archiveModalButtons}</BottomSheetFooter>}
                onClose={handleCancel}
            >
                {archiveModalContent}
            </BottomSheet>
        </>
    );
};

export default translation(memo(ArchiveModal));
