import { ChangeEvent } from 'react';

import { Text, UncontrolledTextArea } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import RatingChooser from 'src/components/VacancyModals/VacancyAdviceModal/RatingChooser';
import ReasonChipsChooser from 'src/components/VacancyModals/VacancyAdviceModal/ReasonChipsChooser';
import { Rating, Reason } from 'src/components/VacancyModals/VacancyAdviceModal/constants';

import styles from './feedback-modal-content.less';

const TrlKeys = {
    question: 'employer.myVacancies.advice.feedback.modal.question',
    chooseReasons: 'employer.myVacancies.advice.feedback.modal.chooseReasons',
    textareaPlaceholder: 'employer.myVacancies.advice.feedback.modal.textarea.placeholder',
};

interface FeedbackModalContentProps {
    selectedRating: Rating | null;
    onSelectRating: (value: Rating) => void;
    onSelectReason: (value: Reason[]) => void;
    onChangeText: (value: string) => void;
    onShownNegativeSection: (element: HTMLDivElement) => void;
}

const MAX_TEXT_LENGTH = 255;

const DUMMY_VALIDATE = () => false;

const FeedbackModalContent: TranslatedComponent<FeedbackModalContentProps> = ({
    trls,
    selectedRating,
    onSelectRating,
    onSelectReason,
    onChangeText,
    onShownNegativeSection,
}) => {
    const onChangeFeedback = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChangeText(event.target.value.trim());
    };

    const isNegativeRating = [Rating.Sad, Rating.Halfsad, Rating.Neutral].includes(selectedRating);

    return (
        <div className={styles.feedbackModalContent}>
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.question]}
            </Text>
            <RatingChooser selected={selectedRating} onSelect={onSelectRating} />
            {isNegativeRating && (
                <div ref={onShownNegativeSection} className={styles.feedbackModalNegativeSection}>
                    <Text typography="label-2-regular" style="secondary">
                        {trls[TrlKeys.chooseReasons]}
                    </Text>
                    <ReasonChipsChooser onSelect={onSelectReason} />
                    <UncontrolledTextArea
                        ref={(el) => {
                            if (el) {
                                el.setAttribute('maxlength', String(MAX_TEXT_LENGTH));
                            }
                        }}
                        onChange={onChangeFeedback}
                        placeholder={trls[TrlKeys.textareaPlaceholder]}
                        maxLength={MAX_TEXT_LENGTH}
                        onValidate={DUMMY_VALIDATE}
                    />
                </div>
            )}
        </div>
    );
};

export default translation(FeedbackModalContent);
