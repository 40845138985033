import { useState } from 'react';
import { useDispatch } from 'react-redux';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { fetchAndUpdateVacanciesCount } from 'src/models/employerVacancies/vacanciesCount';
import { removeUnpremoderatedVacancyAction } from 'src/models/unpremoderatedVacancyList';
import fetcher from 'src/utils/fetcher';

export const FIX_URL = '/employer/unpremoderatedvacancylist/needfix';
export const DISCARD_URL = '/employer/unpremoderatedvacancylist/discard';

declare global {
    interface FetcherPostApi {
        [FIX_URL]: {
            queryParams: void;
            body: {
                id: number;
                comment: string;
            };
            response: {
                id: string;
            };
        };
    }

    interface FetcherPostApi {
        [DISCARD_URL]: {
            queryParams: void;
            body: {
                id: number;
                comment: string;
            };
            response: {
                id: string;
                comment: string;
            };
        };
    }
}

export const useUnpremoderatedVacanciesActionsFetcher = (
    moderatorId: number,
    vacancyId: number
): {
    isLoading: boolean;
    cancelToCompletionUnpremoderatedVacancy: (comment: string) => Promise<void>;
    cancelToDiscardUnpremoderatedVacancy: (comment: string) => Promise<void>;
} => {
    const [isLoading, setIsLoading] = useState(false);

    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const cancelUnpremoderatedVacancy = async (url: typeof FIX_URL | typeof DISCARD_URL, comment: string) => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);

            await fetcher.postFormData(url, {
                id: vacancyId,
                comment,
            });

            dispatch(removeUnpremoderatedVacancyAction({ moderatorId, vacancyId }));
            void fetchAndUpdateVacanciesCount(dispatch, moderatorId);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            setIsLoading(false);
        }
    };

    const cancelToCompletionUnpremoderatedVacancy = (comment: string) => {
        return cancelUnpremoderatedVacancy(FIX_URL, comment);
    };

    const cancelToDiscardUnpremoderatedVacancy = (comment: string) => {
        return cancelUnpremoderatedVacancy(DISCARD_URL, comment);
    };

    return { isLoading, cancelToCompletionUnpremoderatedVacancy, cancelToDiscardUnpremoderatedVacancy };
};
