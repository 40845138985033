import { useSelectorNonNullable } from 'src/hooks/useSelector';

import { SelectedSegment } from 'src/components/VacancyModals/Prolongation/SegmentedControl';

export enum SubmitButtonType {
    None = 'None',
    ProlongateAndBuy = 'ProlongateAndBuy',
    Prolongate = 'Prolongate',
    Purchase = 'Purchase',
    Upgrade = 'Upgrade',
}

const useSubmitButtons = ({ selectedSegment }: { selectedSegment: SelectedSegment }): SubmitButtonType => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);
    const availableToUpgrade = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToUpgrade);

    const anyVacancyCanBeProlongated = availableToFreeProlongateIds.length > 0 || availableToSpendIds.length > 0;

    if (availableToUpgrade && selectedSegment === 'deduct') {
        return SubmitButtonType.Upgrade;
    }

    if (purchase && anyVacancyCanBeProlongated) {
        return SubmitButtonType.ProlongateAndBuy;
    }

    if (purchase) {
        return SubmitButtonType.Purchase;
    }

    if (anyVacancyCanBeProlongated) {
        return SubmitButtonType.Prolongate;
    }

    return SubmitButtonType.None;
};

export default useSubmitButtons;
