import { ContactsAction, FormType, FormValues, ResponseError } from 'src/components/VacancyContactsChangeModal/types';

export const EMPTY_VALUE: FormValues = {
    fio: '',
    email: '',
    phone: '',
    comment: '',
    additionalPhone: '',
    additionalComment: '',
};

export const AVAILABLE_CONTACTS_ACTIONS_MAP = new Map<FormType, ContactsAction[]>([
    ['delete', [ContactsAction.Update, ContactsAction.Hide]],
    ['move', [ContactsAction.Update, ContactsAction.Hide, ContactsAction.DontChange]],
]);

export const trimFormValues = (formValues: FormValues): FormValues => {
    return Object.keys(formValues).reduce<FormValues>((acc, key) => {
        acc[key] = formValues[key].trim();
        return acc;
    }, {} as FormValues);
};

export const getFieldName = (name: string, prefix?: string): string => (prefix ? `${prefix}.${name}` : name);

type Errors = Record<string, string>;
type NestedErrors = Record<string, Errors>;

export const createErrors = (errors: Errors, prefix?: string): Errors | NestedErrors => {
    return prefix ? { [prefix]: errors } : errors;
};

export const errorConstructor = (error: ResponseError = {}, namePrefix?: string): Errors | NestedErrors => {
    const emailError = error.email?.errors[0];
    const fioError = error.fio?.errors[0];
    const phoneError = error.phones?.phone?.['0']?.fullPhoneNumber.errors[0];
    const additionalPhoneError = error.phones?.phone?.['1']?.fullPhoneNumber.errors[0];

    if (error.phoneOrEmailRule?.errors.includes('phoneOrEmailRule')) {
        return createErrors({ email: 'phoneOrEmailRule' }, namePrefix);
    }

    return createErrors(
        {
            ...(emailError && { email: emailError }),
            ...(fioError && { fio: fioError }),
            ...(phoneError && { phone: phoneError }),
            ...(additionalPhoneError && { additionalPhone: additionalPhoneError }),
        },
        namePrefix
    );
};

export const requiredValidator = (value?: string): string | undefined => (value?.trim() ? undefined : 'empty');
