import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppStore } from 'src/app/store';
import { vacancyDraftMoveSuccess } from 'src/components/Notifications/EmployerVacancies';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { removeManagerVacancies } from 'src/models/employerVacancies/managerVacancies';
import { EmployerManager } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

const MOVE_DRAFTS_URL = '/employer/movedrafts';

declare global {
    interface FetcherPostApi {
        [MOVE_DRAFTS_URL]: {
            queryParams: void;
            body: {
                id: number[];
                targetManagerId: number;
            };
            response: void;
        };
    }
}
export default (
        {
            draftsIds,
            managerToTransfer,
        }: {
            draftsIds: number[];
            managerToTransfer: EmployerManager;
            currentManagerId: number;
        },
        addNotification: AddNotification
    ): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> =>
    async (dispatch) => {
        try {
            await fetcher.postFormData(MOVE_DRAFTS_URL, {
                id: draftsIds,
                targetManagerId: managerToTransfer.managerId,
            });
        } catch (err) {
            throw err;
        }
        dispatch(removeManagerVacancies({ vacancyIds: draftsIds }));
        addNotification(vacancyDraftMoveSuccess, { props: { draftsIds } });
    };
