import { RangeToPercent } from 'src/components/VacancyView/SalaryMarketRangeChart/types';

// Значения ЗП раскладываем по процентам
export const RANGE_TO_PERCENT: RangeToPercent = [5, 35, 65, 95];

export const CHART_HEIGHT = 40;
export const MIN_CHART_PERCENT = 0;
export const MAX_CHART_PERCENT = 100;

export const HORIZONTAL_OFFSET = 8;
