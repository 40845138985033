import { FC } from 'react';

import { Upgrade } from 'src/models/prolongateInfo';

import ProlongateAndBuyButton from 'src/components/VacancyModals/Prolongation/ProlongateAndBuyButton';
import ProlongateButton from 'src/components/VacancyModals/Prolongation/ProlongateButton';
import PurchaseButton from 'src/components/VacancyModals/Prolongation/PurchaseButton';
import { SelectedSegment } from 'src/components/VacancyModals/Prolongation/SegmentedControl';
import UpgradeButton from 'src/components/VacancyModals/Prolongation/UpgradeButton';
import useSubmitButtons, { SubmitButtonType } from 'src/components/VacancyModals/Prolongation/useSubmitButtons';

interface SubmitButtonsProps {
    isTimerVisible: boolean;
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    selectedSegment: SelectedSegment;
    vacancyUpgrade: Upgrade | null;
    additionalAnalyticsParams?: Record<string, string>;
}

const SubmitButtons: FC<SubmitButtonsProps> = ({
    isTimerVisible,
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    selectedSegment,
    vacancyUpgrade,
    additionalAnalyticsParams = {},
}) => {
    const submitButtonType = useSubmitButtons({ selectedSegment });

    if (submitButtonType === SubmitButtonType.Upgrade) {
        return (
            <UpgradeButton
                afterSuccessAction={afterSuccessAction}
                // Если кнопка есть, то есть и значение по-умолчанию
                vacancyUpgrade={vacancyUpgrade as Upgrade}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    if (submitButtonType === SubmitButtonType.ProlongateAndBuy) {
        return (
            <ProlongateAndBuyButton
                isTimerVisible={isTimerVisible}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    if (submitButtonType === SubmitButtonType.Purchase) {
        return (
            <PurchaseButton
                isTimerVisible={isTimerVisible}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    if (submitButtonType === SubmitButtonType.Prolongate) {
        return (
            <ProlongateButton
                isTimerVisible={isTimerVisible}
                afterSuccessAction={afterSuccessAction}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    return null;
};

export default SubmitButtons;
