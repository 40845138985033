import { useCallback } from 'react';
import { FormApi } from 'final-form';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { VacancyShareFormData } from 'src/components/HiringManager/types';
import { useSelector } from 'src/hooks/useSelector';
import { ManagerType } from 'src/models/employerManagers.types';
import { ManagersList } from 'src/models/employerManagersPage';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import fetcher from 'src/utils/fetcher';
import { UXFeedback } from 'src/utils/uxfeedback';

const PUT_VACANCY_PERMISSION = '/shards/employer/hiring_managers/share_vacancy';

export interface ShareVacancyPayload {
    vacancyId: string;
    employerManagerId: string;
    permissions: VacancyPermission[];
}

declare global {
    interface FetcherPutApi {
        [PUT_VACANCY_PERMISSION]: {
            queryParams: void;
            body: ShareVacancyPayload[];
            response: void;
        };
    }
}

interface UseShareVacancyParams {
    selectedManagers: ManagersList[];
    vacanciesIds: string[];
    vacanciesNames: string[];
    onClose: () => void;
    onSuccessSubmit?: (selectedManagers: ManagersList[], selectedPermissions: VacancyPermission[]) => void;
}

type UseShareVacancyHook = (
    params: UseShareVacancyParams
) => (data: VacancyShareFormData, form: FormApi<VacancyShareFormData>) => void;

export const useShareVacancy: UseShareVacancyHook = ({
    selectedManagers,
    onSuccessSubmit,
    onClose,
    vacanciesIds,
    vacanciesNames,
}) => {
    const isNotHiringManager = useSelector((state) => !state.isHiringManager);
    const showNotification = useHiringManagerNotification();
    return useCallback(
        async (data: VacancyShareFormData, form: FormApi<VacancyShareFormData>) => {
            try {
                await fetcher.put(
                    PUT_VACANCY_PERMISSION,
                    selectedManagers.reduce((acc, manager) => {
                        return acc.concat(
                            vacanciesIds.map((vacancyId) => ({
                                vacancyId,
                                employerManagerId: manager.id.toString(),
                                permissions:
                                    manager.managerType === ManagerType.McpGroup || manager.type === ManagerType.Mcp
                                        ? [VacancyPermission.ViewContacts, VacancyPermission.ViewDesirableCompensation]
                                        : [
                                              data[VacancyPermission.ViewContacts] && VacancyPermission.ViewContacts,
                                              data[VacancyPermission.ViewDesirableCompensation] &&
                                                  VacancyPermission.ViewDesirableCompensation,
                                          ].filter<VacancyPermission>(
                                              (
                                                  permission: VacancyPermission | boolean
                                              ): permission is VacancyPermission => !!permission
                                          ),
                            }))
                        );
                    }, [] as ShareVacancyPayload[])
                );
                form.reset();
                onClose();
                showNotification?.(vacanciesNames, HiringManagerNotificationType.VacancyShared);
                const permissions = Object.values(VacancyPermission).reduce<VacancyPermission[]>(
                    (acc, permission) => (data[permission] ? [...acc, permission] : acc),
                    []
                );
                onSuccessSubmit?.(selectedManagers, permissions);
                if (isNotHiringManager) {
                    UXFeedback.sendEvent('share_vacancy_to_hiring_manager');
                }
            } catch (_) {
                showNotification?.([], HiringManagerNotificationType.DefaultError);
            }
        },
        [isNotHiringManager, onClose, onSuccessSubmit, selectedManagers, showNotification, vacanciesIds, vacanciesNames]
    );
};
