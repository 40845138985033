import { AnyAction, Dispatch } from 'redux';

import { vacancyUpdateSuccess } from 'src/components/Notifications/EmployerVacancies';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';

import updateVacancyInfoAfterSuccessAction from 'src/components/EmployerVacancies/updateVacancyInfoAfterSuccessAction';

const updateVacancyInfoAfterProlongate =
    ({ addNotification, vacancyIds }: { addNotification: AddNotification; vacancyIds: number[] }) =>
    (dispatch: Dispatch<AnyAction>): void => {
        dispatch(updateVacancyInfoAfterSuccessAction({ addNotification, vacancyIds })).then(
            ({ vacancies }: { vacancies: Vacancy[] }) => {
                addNotification(vacancyUpdateSuccess, {
                    props: { count: vacancies.length },
                });
            },
            (ignore) => {
                console.error(ignore);
            }
        );
    };

export default updateVacancyInfoAfterProlongate;
