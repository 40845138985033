import { useState } from 'react';
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { Button, Card, Input, Text, useBreakpoint, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { IDENTITY_FUNCTION } from 'src/components/Employer/ManagerForm/constants';
import translation from 'src/components/translation';

import { ContactsAction, FieldName, FormValues } from 'src/components/VacancyContactsChangeModal/types';
import { getFieldName, requiredValidator } from 'src/components/VacancyContactsChangeModal/utils';

const TrlKeys = {
    callTrackingHint: 'vacancy.contacts.change.modal.call-tracking.hint',
    addPhone: 'vacancy.contacts.change.modal.button.add.phone',
    placeholder: {
        [FieldName.Fio]: 'vacancy.contacts.change.modal.input.fio',
        [FieldName.Email]: 'vacancy.contacts.change.modal.input.email',
        [FieldName.Phone]: 'vacancy.contacts.change.modal.input.phone',
        [FieldName.Comment]: 'vacancy.contacts.change.modal.input.comment',
        [FieldName.AdditionalPhone]: 'vacancy.contacts.change.modal.input.phone',
        [FieldName.AdditionalComment]: 'vacancy.contacts.change.modal.input.comment',
    },
    errors: {
        empty: 'vacancy.contacts.change.modal.error.empty',
        default: 'vacancy.contacts.change.modal.error.default',

        fio: 'vacancy.contacts.change.modal.error.fio',
        email: 'vacancy.contacts.change.modal.error.email.longEmail',
        emailPatternRule: 'vacancy.contacts.change.modal.error.emailPatternRule',
        fullPhoneNumberValidation: 'vacancy.contacts.change.modal.error.fullPhoneNumberValidation',
        phoneOrEmailRule: 'vacancy.contacts.change.modal.error.phoneOrEmailRule',
        phoneComment: 'vacancy.contacts.change.modal.error.phoneComment',
        minLength: 'vacancy.contacts.change.modal.error.minLength',
        fullPhoneNumber: 'vacancy.contacts.change.modal.error.fullPhoneNumber',
        required: 'vacancy.contacts.change.modal.error.required',
    },
    hints: {
        newContacts: 'vacancy.contacts.change.modal.new.contacts.hint',
        newContactsMany: 'vacancy.contacts.change.modal.new.contacts.hint.many',
        hide: 'vacancy.contacts.change.modal.hide.hint',
        hideMany: 'vacancy.contacts.change.modal.hide.hint.many',
        dontChange: 'vacancy.contacts.change.modal.dont.change.hint',
        dontChangeMany: 'vacancy.contacts.change.modal.dont.change.hint.many',
    },
};

const InputField: TranslatedComponent<{
    name: FieldName;
    prefix?: string;
    initialValue?: string;
    validate?: FieldValidator<string>;
    enableIdentity?: boolean;
}> = ({ name, prefix, initialValue, validate, enableIdentity, trls }) => {
    const renderError = (meta: FieldMetaState<string>) => {
        const error =
            meta &&
            meta.submitFailed &&
            meta.touched &&
            !meta.dirtySinceLastSubmit &&
            ((meta.error as string) || (meta.submitError as string));
        if (!error) {
            return null;
        }

        return trls[
            error in TrlKeys.errors ? TrlKeys.errors[error as keyof typeof TrlKeys.errors] : TrlKeys.errors.default
        ];
    };

    return (
        <FinalField
            name={getFieldName(name, prefix)}
            initialValue={initialValue}
            validate={validate}
            parse={enableIdentity ? IDENTITY_FUNCTION : undefined}
            render={({ input, meta }) => {
                const currentError = renderError(meta);
                return (
                    <Input
                        {...input}
                        maxLength={255}
                        size="large"
                        elevatePlaceholder
                        autoCorrect="off"
                        autoCapitalize="off"
                        errorMessage={currentError}
                        spellCheck="false"
                        placeholder={trls[TrlKeys.placeholder[name]]}
                        invalid={!!currentError}
                    />
                );
            }}
        />
    );
};

interface ChangeContactsFormProps {
    actionType: ContactsAction;
    namePrefix?: string;
    initialValues: FormValues;
    vacanciesCount?: number;
    enableIdentity?: boolean;
}

const ChangeContactsForm: TranslatedComponent<ChangeContactsFormProps> = ({
    trls,
    actionType,
    initialValues,
    vacanciesCount,
    namePrefix,
    enableIdentity = false,
}) => {
    const { isMobile } = useBreakpoint();
    const [isAdditionalPhone, setIsAdditionalPhone] = useState(false);

    if (actionType === ContactsAction.Update) {
        return (
            <>
                <Text typography="label-2-regular" style="secondary">
                    {!vacanciesCount ? (
                        trls[TrlKeys.hints.newContactsMany]
                    ) : (
                        <ConversionNumber
                            hasValue={false}
                            value={vacanciesCount}
                            one={trls[TrlKeys.hints.newContacts]}
                            some={trls[TrlKeys.hints.newContactsMany]}
                            many={trls[TrlKeys.hints.newContactsMany]}
                        />
                    )}
                </Text>
                <VSpacing default={12} />
                <VSpacingContainer default={12}>
                    {[FieldName.Fio, FieldName.Email, FieldName.Phone].map((name) => (
                        <InputField
                            key={name}
                            name={name}
                            prefix={namePrefix}
                            initialValue={initialValues[name]}
                            trls={trls}
                            validate={name === FieldName.Fio ? requiredValidator : undefined}
                            enableIdentity={enableIdentity}
                        />
                    ))}
                </VSpacingContainer>
                <VSpacing default={8} />
                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.callTrackingHint]}
                </Text>
                <VSpacing default={12} />
                <InputField
                    name={FieldName.Comment}
                    prefix={namePrefix}
                    initialValue={initialValues[FieldName.Comment]}
                    trls={trls}
                />
                <VSpacing default={12} />
                <Button
                    mode="secondary"
                    style="neutral"
                    onClick={() => setIsAdditionalPhone(true)}
                    icon={<PlusOutlinedSize24 />}
                    stretched={isMobile}
                >
                    {trls[TrlKeys.addPhone]}
                </Button>
                <VSpacing default={12} />
                {(isAdditionalPhone || initialValues.additionalPhone) && (
                    <>
                        <InputField
                            name={FieldName.AdditionalPhone}
                            prefix={namePrefix}
                            initialValue={initialValues[FieldName.AdditionalPhone]}
                            trls={trls}
                        />
                        <VSpacing default={12} />
                        <InputField
                            name={FieldName.AdditionalComment}
                            prefix={namePrefix}
                            initialValue={initialValues[FieldName.AdditionalComment]}
                            trls={trls}
                        />
                        <VSpacing default={12} />
                    </>
                )}
            </>
        );
    }

    if (actionType === ContactsAction.Hide) {
        return (
            <Card padding={16} borderRadius={16} style="neutral">
                <Text typography="paragraph-2-regular">
                    {!vacanciesCount ? (
                        trls[TrlKeys.hints.hideMany]
                    ) : (
                        <ConversionNumber
                            hasValue={false}
                            value={vacanciesCount}
                            one={trls[TrlKeys.hints.hide]}
                            some={trls[TrlKeys.hints.hideMany]}
                            many={trls[TrlKeys.hints.hideMany]}
                        />
                    )}
                </Text>
            </Card>
        );
    }

    return (
        <Card padding={16} borderRadius={16} style="warning">
            <Text typography="paragraph-2-regular">
                {!vacanciesCount ? (
                    trls[TrlKeys.hints.dontChangeMany]
                ) : (
                    <ConversionNumber
                        hasValue={false}
                        value={vacanciesCount}
                        one={trls[TrlKeys.hints.dontChange]}
                        some={trls[TrlKeys.hints.dontChangeMany]}
                        many={trls[TrlKeys.hints.dontChangeMany]}
                    />
                )}
            </Text>
        </Card>
    );
};

export default translation(ChangeContactsForm);
