import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    continue: 'vacancy.contacts.change.modal.button.continue',
    back: 'vacancy.contacts.change.modal.button.back',
};

const RightButtonsComponent: TranslatedComponent<{
    backStep: () => void;
    isSubmitting: boolean;
}> = ({ trls, backStep, isSubmitting }) => (
    <>
        <Button mode="secondary" style="accent" onClick={backStep}>
            {trls[TrlKeys.back]}
        </Button>
        <Button
            mode="primary"
            style="accent"
            disabled={isSubmitting}
            loading={isSubmitting}
            form="change-contacts-form"
            type="submit"
        >
            {trls[TrlKeys.continue]}
        </Button>
    </>
);

export default translation(RightButtonsComponent);
