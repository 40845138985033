import { FC, ReactNode } from 'react';

import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { SalaryRange } from '@hh.ru/types-hh-microcore';

import { MarketRange as TMarketRange } from 'src/models/canvacVacancySalaryData';
import { VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';

import ChartLegendInfo from 'src/components/VacancyView/SalaryMarketRangeChart/ChartLegendInfo';
import MarketRangeText from 'src/components/VacancyView/SalaryMarketRangeChart/MarketRangeText';
import SalaryRangeChart from 'src/components/VacancyView/SalaryMarketRangeChart/SalaryRangeChart';
import TicksXAxis from 'src/components/VacancyView/SalaryMarketRangeChart/TicksXAxis';
import { CHART_HEIGHT, HORIZONTAL_OFFSET } from 'src/components/VacancyView/SalaryMarketRangeChart/constants';
import { MarketRange } from 'src/components/VacancyView/SalaryMarketRangeChart/types';

import styles from './salary-market-range-chart.less';

interface SalaryMarketRangeChartProps {
    compensation: Partial<VacancyCompensation>;
    marketRange: TMarketRange;
    salaryRange: SalaryRange;
    infoComponent?: ReactNode;
    onChartClick?: () => void;
    overrideColor?: boolean;
}

export const getCssColor = (salaryRange: SalaryRange, overrideColor?: boolean): string => {
    if (overrideColor && salaryRange === SalaryRange.MatchMarket) {
        return styles.chartMatchMarketColor;
    }
    if ([SalaryRange.MatchMarket, SalaryRange.TooWide].includes(salaryRange)) {
        return styles.chartMarketColor;
    }
    if ([SalaryRange.High, SalaryRange.TooHigh].includes(salaryRange)) {
        return styles.chartAboveMarketColor;
    }
    return styles.chartOffMarketColor;
};

const SalaryMarketRangeChart: FC<SalaryMarketRangeChartProps> = ({
    compensation,
    marketRange,
    salaryRange,
    infoComponent,
    onChartClick,
    overrideColor,
}) => {
    if (!marketRange) {
        return null;
    }

    const cssClass = getCssColor(salaryRange, overrideColor);
    const data: MarketRange = [marketRange.minimal, marketRange.bottom, marketRange.upper, marketRange.maximal];

    return (
        <div className={cssClass}>
            <VSpacingContainer default={12}>
                <MarketRangeText />
                <div className={styles.chartBackground} onClick={onChartClick}>
                    <SalaryRangeChart
                        data={data}
                        compensation={compensation}
                        height={CHART_HEIGHT}
                        hOffset={HORIZONTAL_OFFSET}
                    />
                </div>
                <div className={styles.chartTicksWrapper}>
                    <TicksXAxis data={data} height={24} hOffset={HORIZONTAL_OFFSET} salaryRange={salaryRange} />
                </div>
            </VSpacingContainer>
            <VSpacing default={24} />
            <div className={styles.chartInfoWrapper}>
                <ChartLegendInfo compensation={compensation} />
                {infoComponent}
            </div>
        </div>
    );
};

export default SalaryMarketRangeChart;
