import { FC } from 'react';

import { Button, useBreakpoint, ButtonMode, ButtonSize, ButtonStyle } from '@hh.ru/magritte-ui';
import { PenOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { TriggerType } from 'src/components/ControlsTrigger';
import EditAction from 'src/components/VacancyActions/EditAction';
import { UnpremoderatedVacancy } from 'src/models/unpremoderatedVacancyList';

interface ArchiveButtonProps {
    vacancy: UnpremoderatedVacancy;
}

const EditButton: FC<ArchiveButtonProps> = ({ vacancy }) => {
    const { isMobile } = useBreakpoint();

    const buttonProps = isMobile
        ? {
              icon: <PenOutlinedSize24 />,
              mode: 'tertiary' as ButtonMode,
              style: 'neutral' as ButtonStyle,
              size: 'large' as ButtonSize,
          }
        : {
              mode: 'secondary' as ButtonMode,
              style: 'accent' as ButtonStyle,
              size: 'small' as ButtonSize,
          };

    return (
        <EditAction
            Component={(props) => (
                <Button Element="a" href={props.triggerHref} stretched={true} {...buttonProps}>
                    {props.children}
                </Button>
            )}
            triggerType={TriggerType.Button}
            vacancyId={vacancy.id}
        />
    );
};

export default EditButton;
