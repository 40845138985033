import { ButtonIconPosition } from 'bloko/blocks/button';

export enum TriggerType {
    ActionList = 'actionList',
    Batch = 'batch',
    Button = 'button',
    ButtonMenu = 'buttonMenu',
    Link = 'link',
    Menu = 'menu',
    VacancyAdviceModalButton = 'vacancyAdviceModalButton',
    VacancyAdviceCompetitionCardButton = 'vacancyAdviceCompetitionCardButton',
}

export interface BasicTriggerProps {
    children?: React.ReactNode;
    triggerType: TriggerType;
    triggerName: string;
    onTrigger?: (isFromRunAction?: boolean) => void;
    triggerHref?: string;
    triggerTarget?: '_blank';
    icon?: React.ReactElement;
    iconPosition?: ButtonIconPosition;
    isPermitted?: boolean;
    isLoading?: boolean;
    actionType?: ControlsTriggerActionType;
    additionalAnalyticsParams?: Record<string, string>;
    batchLength?: number;
    magritteRedesign?: boolean;
    counter?: string;
    displayInOwnColumn?: boolean;
}

export type BasicTriggerPropsWithoutTriggerName = Omit<BasicTriggerProps, 'triggerName'>;

export enum ControlsTriggerActionType {
    NEUTRAL = 'neutral',
    LIGHT = 'light',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}
