import { Dispatch, FC, useState } from 'react';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';

import { VacancyAdviceInfo, VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';
import { VacancyAdvice, VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';
import { MIDDLE_DOT } from 'src/utils/constants/symbols';

import VacancyAdviceCard from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdviceCard';
import VacancyAdviceModalMagritte from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalMagritte';
import { sortBySeverity } from 'src/components/VacancyModals/VacancyAdviceModal/utils';

interface VacancyAdviceModalProps extends VacancyAdviceInfo {
    vacancyId: number;
    vacancyName: string;
    vacancyAreaName: string;
    isVisible: boolean;
    compensation: VacancyCompensation;
    setAdvices: (advices: VacancyAdviceWithStatistics[]) => void;
    handleCloseModal: () => void;
    dispatchModal: Dispatch<AnyAction>;
    numberOfSimilarVacancies: number;
}

const VacancyAdviceModal: FC<VacancyAdviceModalProps> = ({
    adviceWithStatistics,
    setAdvices,
    compensation,
    marketRange,
    salaryRange,
    vacancyId,
    vacancyName,
    vacancyAreaName,
    isVisible,
    handleCloseModal,
    dispatchModal,
    numberOfSimilarVacancies,
}) => {
    const [modalAdvice, setModalAdvice] = useState(adviceWithStatistics);
    const adviceSorted = sortBySeverity(modalAdvice);

    const hideAdviceItem = (adviceItem: VacancyAdvice) => {
        const newAdvices = modalAdvice.filter((advice) => advice.advice !== adviceItem);
        setAdvices(newAdvices);
        setModalAdvice(newAdvices);
        if (newAdvices.length === 0) {
            handleCloseModal();
        }
    };

    const onCloseModal = () => {
        handleCloseModal();
        Analytics.sendHHEvent('employer_vacancies_advice_modal_close', {
            vacancyId,
        });
    };

    const modalContent = adviceSorted.map((adviceWithStatistic, ind) => {
        return (
            <VacancyAdviceCard
                key={adviceWithStatistic.advice}
                adviceItem={adviceWithStatistic.advice}
                adviceWithStatisticsItem={adviceWithStatistic}
                numInList={ind + 1}
                totalInList={adviceSorted.length}
                vacancyId={vacancyId}
                compensation={compensation}
                marketRange={marketRange}
                salaryRange={salaryRange}
                hideAdviceItem={hideAdviceItem}
                vacancyName={vacancyName}
                numberOfSimilarVacancies={numberOfSimilarVacancies}
                dispatchModal={dispatchModal}
            />
        );
    });

    return (
        <VacancyAdviceModalMagritte
            advices={adviceSorted.map(({ advice }) => advice)}
            visible={isVisible}
            onClose={onCloseModal}
            vacancyId={vacancyId}
            titleDescription={`${vacancyName} ${MIDDLE_DOT} ${vacancyAreaName}`}
        >
            {modalContent}
        </VacancyAdviceModalMagritte>
    );
};

export default VacancyAdviceModal;
