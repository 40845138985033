import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Action,
    Modal,
    useBreakpoint,
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import fetchFilteredGroupsInfo from 'src/components/EmployerVacancies/fetchFilteredGroupsInfo';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import disableAutoPublication from 'src/components/VacancyModals/AutoPublication/disableAutoPublication';

const TrlKeys = {
    disable: 'employer.vacancies.autoPublication.modal.disable.button',
    cancel: 'employer.vacancies.autoPublication.modal.disable.cancel',
    modalTitle: 'employer.vacancies.autoPublication.modal.disable.title',
    modalText: 'employer.vacancies.autoPublication.modal.disable.text',
};

interface DisableAutoPublicationModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    vacancyName: string;
    draftId: number;
}

const DisableAutoPublicationModal: TranslatedComponent<DisableAutoPublicationModalProps> = ({
    isVisible,
    handleCloseModal,
    draftId,
    vacancyName,
    trls,
}) => {
    const [isLoading, setLoading] = useState(false);
    const { addNotification } = useNotification();
    const activeGroupId = useSelectorNonNullable(
        ({ managerVacanciesActiveFilteredGroupId }) => managerVacanciesActiveFilteredGroupId
    );
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();

    const handleAutoPublicationDisable = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setLoading(true);

        try {
            await dispatch(disableAutoPublication(draftId, addNotification));

            if (activeGroupId) {
                await dispatch(fetchFilteredGroupsInfo(activeGroupId));
            }
        } finally {
            setLoading(false);
            handleCloseModal();
        }
    }, [dispatch, addNotification, draftId, activeGroupId, handleCloseModal, isLoading]);

    const title = trls[TrlKeys.modalTitle];

    const content = (
        <div data-qa="vacancy-auto-publication-disable-info">
            {formatToReactComponent(trls[TrlKeys.modalText], {
                '{name}': vacancyName,
            })}
        </div>
    );

    const controls = (
        <>
            <Button mode="secondary" style="accent" onClick={handleCloseModal}>
                {trls[TrlKeys.cancel]}
            </Button>
            <Button
                key="disable"
                mode="primary"
                style="accent"
                onClick={handleAutoPublicationDisable}
                loading={isLoading}
                data-qa="vacancy-auto-publication-disable-button"
            >
                {trls[TrlKeys.disable]}
            </Button>
        </>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                titleSize="medium"
                visible={isVisible}
                onClose={handleCloseModal}
                footer={<ActionBar primaryActions={controls} />}
                title={title}
            >
                {content}
            </Modal>

            {isMobile && (
                <BottomSheet
                    visible={isVisible}
                    header={<NavigationBar title={title} />}
                    footer={<BottomSheetFooter>{controls}</BottomSheetFooter>}
                    onClose={handleCloseModal}
                >
                    {content}
                </BottomSheet>
            )}
        </>
    );
};

export default translation(DisableAutoPublicationModal);
