import { useState, useRef, useCallback, useMemo, useLayoutEffect, FC, PropsWithChildren } from 'react';

import { useResize } from '@hh.ru/magritte-ui';
import throttle from 'bloko/common/throttle';

import { MIN_CHART_PERCENT, MAX_CHART_PERCENT } from 'src/components/VacancyView/SalaryMarketRangeChart/constants';
import { getXAxis } from 'src/components/VacancyView/SalaryMarketRangeChart/utils';

import ChartContext from 'src/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';

import styles from './chart-container.less';

const CHART_WIDTH = 320;
const CHART_THROTTLE_DELAY_MS = 400;
export interface ChartContainerProps {
    height: number;
    /**
     * Горизонтальное смещение относильно графика
     */
    hOffset?: number;
}

const ChartContainer: FC<ChartContainerProps & PropsWithChildren> = ({ children, height, hOffset = 0 }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(CHART_WIDTH);

    const WIDTH_WITH_MARGIN = width - hOffset * 2;

    const xAxis = useMemo(() => getXAxis(MIN_CHART_PERCENT, MAX_CHART_PERCENT, WIDTH_WITH_MARGIN), [WIDTH_WITH_MARGIN]);

    const recalcChart = useCallback(() => {
        const containerWidth = containerRef.current?.getBoundingClientRect()?.width;
        setWidth(containerWidth || CHART_WIDTH);
    }, []);

    useLayoutEffect(() => {
        if (containerRef.current) {
            recalcChart();
        }
    }, [recalcChart]);

    useResize(throttle(recalcChart, CHART_THROTTLE_DELAY_MS));

    return (
        <div ref={containerRef} className={styles.chartContainer}>
            <ChartContext.Provider value={{ xAxis, hOffset }}>
                <svg
                    style={{ overflow: 'visible' }}
                    width={WIDTH_WITH_MARGIN}
                    height={height}
                    viewBox={`0 0 ${WIDTH_WITH_MARGIN} ${height}`}
                >
                    {children}
                </svg>
            </ChartContext.Provider>
        </div>
    );
};

export default ChartContainer;
