import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './available-to-free-prolongate.less';

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.availableToFreeProlongate.title',
    unit: 'employer.vacancyRisePage.cart.unit',
};

const AvailableToFreeProlongate: TranslatedComponent = ({ trls }) => {
    const freeLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds.length
    );
    const purchaseLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds.length
    );
    const spendLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds.length
    );
    const unavailableLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.unavailable.vacancyIds.length
    );

    if (!freeLength || (!!freeLength && !purchaseLength && !spendLength && !unavailableLength)) {
        return null;
    }

    return (
        <>
            <VSpacing default={16} />
            <div className={styles.wrapper}>
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                <Text typography="label-3-regular" style="secondary">
                    {`${freeLength}${NON_BREAKING_SPACE}${trls[TrlKeys.unit]}`}
                </Text>
            </div>
        </>
    );
};

export default translation(AvailableToFreeProlongate);
