import { ReactElement } from 'react';

import {
    SquaresIntersectedSize24,
    FolderOutlinedSize24,
    PenSquareOutlinedSize24,
    CalendarOutlinedSize24,
    AstrostarOutlinedSize24,
    ArrowUpOutlinedSize24,
    LinkOutlinedSize24,
    TrashOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

export const BUTTON_ICONS: Record<string, ReactElement> = {
    archive: <FolderOutlinedSize24 />,
    edit: <PenSquareOutlinedSize24 />,
    'auto-prolongation-toggle': <CalendarOutlinedSize24 />,
    duplicate: <SquaresIntersectedSize24 />,
    upgrade: <AstrostarOutlinedSize24 />,
    'option-toggle': <ArrowUpOutlinedSize24 />,
    videointerview: <LinkOutlinedSize24 />,
    'hide-archived-vacancies': <TrashOutlinedSize24 initial="negative" />,
    'restore-archived-vacancies': <ArrowUpOutlinedSize24 />,
} as const;

const getMenuButtonIconByName = (triggerName: string): ReactElement | undefined => BUTTON_ICONS[triggerName];
export default getMenuButtonIconByName;
