import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import SalaryInfo from 'src/components/SalaryInfo';
import SalaryMarketRangeChart from 'src/components/VacancyView/SalaryMarketRangeChart';
import translation from 'src/components/translation';
import { MarketRange } from 'src/models/canvacVacancySalaryData';
import { VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';
import { COLON, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import SalaryAdviceTooltip from 'src/components/VacancyModals/VacancyAdviceModal/SalaryAdviceTooltip';
import { SALARY_VACANCY_ADVICES } from 'src/components/VacancyModals/VacancyAdviceModal/constants';

interface AdviceCardContentProps {
    title: string;
    description: string;
    adviceItem: VacancyAdvice;
    compensation: VacancyCompensation;
    marketRange: MarketRange;
    salaryRange: SalaryRange;
    onChartClick: () => void;
    vacancyId: number;
    vacancyName: string;
    numberOfSimilarVacancies: number;
}

const MAX_SIMILAR_VACANCIES = 300;

const TrlKeys = {
    raiseVacancyDescription: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description',
    similar: {
        one: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description.similar.one',
        some: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description.similar.some',
        many: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description.similar.many',
    },
    more: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description.more',
};

const AdviceCardContent: TranslatedComponent<AdviceCardContentProps> = ({
    title,
    description,
    adviceItem,
    compensation,
    marketRange,
    salaryRange,
    vacancyId,
    onChartClick,
    vacancyName,
    numberOfSimilarVacancies,
    trls,
}) => {
    const isSalaryAdvice = SALARY_VACANCY_ADVICES.includes(adviceItem);

    const getAdviceTitle = () => {
        return (
            <>
                {title}
                {[VacancyAdvice.SalaryCloserToMarket, VacancyAdvice.PublishSalary].includes(adviceItem) && (
                    <SalaryInfo
                        startText={`${COLON}${NON_BREAKING_SPACE}`}
                        from={marketRange?.bottom}
                        to={marketRange?.upper}
                        currency={marketRange?.currency}
                    />
                )}
            </>
        );
    };

    const descriptionText = (
        <Text Element="span" style="secondary" typography="paragraph-3-regular">
            {adviceItem === VacancyAdvice.RaiseVacancy
                ? formatToReactComponent(trls[TrlKeys.raiseVacancyDescription], {
                      '{0}': <>{vacancyName}</>,
                      '{1}': (
                          <>
                              {numberOfSimilarVacancies >= MAX_SIMILAR_VACANCIES ? `${trls[TrlKeys.more]} ` : ''}
                              <ConversionNumber
                                  many={trls[TrlKeys.similar.many]}
                                  some={trls[TrlKeys.similar.some]}
                                  one={trls[TrlKeys.similar.one]}
                                  zero={trls[TrlKeys.similar.many]}
                                  value={
                                      numberOfSimilarVacancies >= MAX_SIMILAR_VACANCIES
                                          ? MAX_SIMILAR_VACANCIES
                                          : numberOfSimilarVacancies
                                  }
                              />
                          </>
                      ),
                  })
                : description}
        </Text>
    );

    return (
        <div>
            <Text typography="subtitle-1-semibold" Element="span">
                {getAdviceTitle()}
            </Text>
            <VSpacing default={8} />
            {isSalaryAdvice ? (
                <>
                    {descriptionText}
                    <VSpacing default={16} />
                    <SalaryMarketRangeChart
                        compensation={compensation}
                        marketRange={marketRange}
                        salaryRange={salaryRange}
                        infoComponent={
                            <SalaryAdviceTooltip vacancyId={vacancyId} analyticEventName="salary_advice_tooltip" />
                        }
                        onChartClick={onChartClick}
                    />
                </>
            ) : (
                <>{descriptionText}</>
            )}
        </div>
    );
};

export default translation(AdviceCardContent);
