import { ChangeEvent, FC } from 'react';

import { Card, Cell, CellText, Radio } from '@hh.ru/magritte-ui';

import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { Upgrade as UpgradeType } from 'src/models/prolongateInfo';

import { SelectedSegment } from 'src/components/VacancyModals/Prolongation/SegmentedControl';

import styles from './upgrade.less';

interface UpgradeProps {
    vacancyUpgrade: UpgradeType | null;
    selectedSegment: SelectedSegment;
    handleChangeVacancyUpgrade: (value: UpgradeType) => void;
}

const Upgrade: FC<UpgradeProps> = ({ vacancyUpgrade, selectedSegment, handleChangeVacancyUpgrade }) => {
    const availableToUpgrade = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToUpgrade);

    if (!availableToUpgrade || selectedSegment === 'purchase' || !vacancyUpgrade) {
        return null;
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = availableToUpgrade.find((vacancy) => vacancy.service === event.target.value) as UpgradeType;
        handleChangeVacancyUpgrade(value);
    };

    return (
        <div className={styles.wrapper}>
            {availableToUpgrade.map(({ service, serviceTrl }) => (
                <Card
                    key={service}
                    stretched
                    showBorder
                    padding={16}
                    borderRadius={16}
                    data-qa={`prolongate-upgrade_${service}`}
                >
                    <Cell
                        left={
                            <Radio
                                name="service"
                                value={service}
                                checked={service === vacancyUpgrade.service}
                                onChange={onChange}
                            />
                        }
                    >
                        <CellText>{serviceTrl}</CellText>
                    </Cell>
                </Card>
            ))}
        </div>
    );
};

export default Upgrade;
