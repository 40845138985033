// tempexp_34940_file
import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Banner, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    advice: 'vacancy.prolongate.vacancyAdvice',
};

const sendEventElementShownAdvice = (vacancyId: number) =>
    Analytics.sendHHEvent('element_shown', {
        elementName: 'prolongation_modal_advice',
        vacancyId,
    });
interface AdviceProps {
    vacancyId: number;
}
const Advice: TranslatedComponent<AdviceProps> = ({ trls, vacancyId }) => {
    useEffect(() => {
        sendEventElementShownAdvice(vacancyId);
    }, [vacancyId]);
    return (
        <>
            <Banner style="extra-1" showClose={false} stretched content={<Text>{trls[TrlKeys.advice]}</Text>} />
            <VSpacing default={16} />
        </>
    );
};

export default translation(Advice);
