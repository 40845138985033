import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    cancel: 'vacancy.contacts.change.modal.button.cancel',
};

const LeftButtonsComponent: TranslatedComponent<{
    handleCloseModal: () => void;
}> = ({ trls, handleCloseModal }) => (
    <Button mode="tertiary" style="accent" onClick={handleCloseModal}>
        {trls[TrlKeys.cancel]}
    </Button>
);

export default translation(LeftButtonsComponent);
