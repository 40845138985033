import { ReactNode, useState, useRef, useCallback } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    Title,
    useBreakpoint,
    Text,
    Button,
    TooltipHover,
    VSpacing,
    HSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, ExclamationCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import useOnOffState from 'src/hooks/useOnOffState';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';

import FeedbackModalContent from 'src/components/VacancyModals/VacancyAdviceModal/FeedbackModalContent';
import MobileTip from 'src/components/VacancyModals/VacancyAdviceModal/MobileTip';
import RatingChooser from 'src/components/VacancyModals/VacancyAdviceModal/RatingChooser';
import vacancyAdviceModalFeedbackSuccess from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalFeedbackSuccess';
import { Rating, Reason } from 'src/components/VacancyModals/VacancyAdviceModal/constants';
import { noop } from 'src/components/VacancyModals/VacancyAdviceModal/utils';

import styles from './vacancy-advice-modal-magritte.less';

interface VacancyAdviceModalMagritteProps {
    visible: boolean;
    titleDescription: string;
    vacancyId: number;
    onClose: () => void;
    advices: VacancyAdvice[];
    children: ReactNode;
}

const TrlKeys = {
    title: 'employer.myVacancies.advice.feedback.modal.title',
    feedbackText: 'employer.myVacancies.advice.feedback.modal.parent.text',
    sendButton: 'employer.myVacancies.advice.feedback.modal.buttons.send',
    backButton: 'Back',
    helpfulAdvice: {
        one: 'employer.myVacancies.advice.helpfulAdvice.one',
        some: 'employer.myVacancies.advice.helpfulAdvice.some',
        many: 'employer.myVacancies.advice.helpfulAdvice.many',
    },
    helpfulAdviceHint1: 'employer.myVacancies.advice.helpfulAdviceHint.1',
    helpfulAdviceHint2: 'employer.myVacancies.advice.helpfulAdviceHint.2',
    close: 'close',
    tooltipTitle: 'employer.myVacancies.advice.tooltip.title.aboutAdvices',
};

interface FormValues {
    reasons: Reason[];
    comment: string;
}

const DEFAULT_FORM_VALUES: FormValues = {
    reasons: [],
    comment: '',
};

const VacancyAdviceModalMagritte: TranslatedComponent<VacancyAdviceModalMagritteProps> = ({
    trls,
    advices,
    visible,
    titleDescription,
    vacancyId,
    onClose,
    children,
}) => {
    const { isMobile, isXS } = useBreakpoint();
    const [selectedRating, setSelectedRating] = useState<Rating | null>(null);
    const formValues = useRef<FormValues>(DEFAULT_FORM_VALUES);
    const { addNotification } = useNotification();

    const activatorRef = useRef<HTMLSpanElement>(null);

    const [visibleMobileTip, onShowMobileTip, onCloseMobileTip] = useOnOffState(false);

    const tooltipAdivices = (
        <>
            {trls[TrlKeys.helpfulAdviceHint1]}
            <VSpacing default={4} />
            {trls[TrlKeys.helpfulAdviceHint2]}
        </>
    );
    const onAppearTip = () => {
        Analytics.sendHHEvent('tags_advice_modal_header_info_shown', { vacancyId });
    };

    const modalTitle = (
        <>
            <Title size="medium" Element="h4">
                {selectedRating ? (
                    trls[TrlKeys.title]
                ) : (
                    <>
                        <Conversion
                            value={advices.length}
                            one={trls[TrlKeys.helpfulAdvice.one]}
                            some={trls[TrlKeys.helpfulAdvice.some]}
                            many={trls[TrlKeys.helpfulAdvice.many]}
                            hasValue={advices.length > 1}
                        />
                        <HSpacing default={8} />
                        <span ref={activatorRef} onClick={isMobile ? onShowMobileTip : noop}>
                            <ExclamationCircleOutlinedSize16 initial="secondary" />
                        </span>
                    </>
                )}
            </Title>
            <TooltipHover placement="right-top" activatorRef={activatorRef} onAppear={onAppearTip}>
                {tooltipAdivices}
            </TooltipHover>
        </>
    );

    const onReset = () => {
        setSelectedRating(null);
        formValues.current = DEFAULT_FORM_VALUES;
    };

    const onCloseModal = () => {
        onReset();
        onClose();
    };

    const onCancelChoiceRating = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_cancel', {
            vacancyId,
        });
        onReset();
    };

    const onSendFeedback = () => {
        Analytics.sendHHEvent('employer_vacancies_advice_feedback_sent', {
            vacancyId,
            rating: selectedRating,
            reasons: JSON.stringify(formValues.current.reasons),
            comment: formValues.current.comment,
        });

        addNotification(vacancyAdviceModalFeedbackSuccess);
        onCloseModal();
    };

    const onSelectRating = (rating: Rating) => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_rating_item', {
            vacancyId,
            rating,
        });

        setSelectedRating(rating);
    };

    const onSelectReason = (reasons: Reason[]) => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_reason_item', {
            vacancyId,
            reason: JSON.stringify(reasons),
        });

        formValues.current.reasons = reasons;
    };

    const onChangeFeedbackText = (text: string) => {
        formValues.current.comment = text;
    };

    const onShownNegativeSection = useCallback(
        (element: HTMLDivElement) => {
            if (element) {
                Analytics.sendHHEventElementShown(element, {
                    name: 'employer_vacancies_advice_feedback_reason',
                    vacancyId,
                });
            }
        },
        [vacancyId]
    );

    const modalAdviceContentRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_modal_shown',
        vacancyId,
        advices: JSON.stringify(advices),
    });

    const modalFeedbackContentRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_feedback_modal',
        vacancyId,
        rating: selectedRating,
    });

    const feedbackTextRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_feedback_button',
        vacancyId,
    });

    const modalContent = (
        <div ref={modalAdviceContentRef}>
            {selectedRating ? (
                <div ref={modalFeedbackContentRef}>
                    <FeedbackModalContent
                        selectedRating={selectedRating}
                        onSelectRating={onSelectRating}
                        onSelectReason={onSelectReason}
                        onChangeText={onChangeFeedbackText}
                        onShownNegativeSection={onShownNegativeSection}
                    />
                </div>
            ) : (
                children
            )}
        </div>
    );

    const leftButtons = (
        <span ref={feedbackTextRef}>
            {selectedRating ? (
                <Button mode="secondary" style="accent" onClick={onCancelChoiceRating}>
                    {trls[TrlKeys.backButton]}
                </Button>
            ) : (
                <Text>{trls[TrlKeys.feedbackText]}</Text>
            )}
        </span>
    );

    const rightButtons = selectedRating ? (
        <Button mode="primary" style="accent" onClick={onSendFeedback}>
            {trls[TrlKeys.sendButton]}
        </Button>
    ) : (
        <RatingChooser selected={selectedRating} onSelect={onSelectRating} />
    );

    const subtitle = selectedRating ? undefined : (
        <Text typography="label-2-regular" style="secondary">
            {titleDescription}
        </Text>
    );

    return isMobile ? (
        <>
            <BottomSheet
                header={
                    <NavigationBar
                        title={<div className={styles.vacancyAdviceModalBottomSheetTitle}>{modalTitle}</div>}
                        subtitle={subtitle}
                        showDivider="always"
                        right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                    />
                }
                visible={visible}
                onClose={onCloseModal}
                showDivider="with-scroll"
                footer={
                    <BottomSheetFooter>
                        <div
                            className={classnames(styles.vacancyAdviceModalBottomSheetFooter, {
                                [styles.vacancyAdviceModalBottomSheetFooterColumn]: isXS && !selectedRating,
                            })}
                        >
                            {leftButtons}
                            {rightButtons}
                        </div>
                    </BottomSheetFooter>
                }
            >
                {modalContent}
            </BottomSheet>
            <MobileTip
                title={trls[TrlKeys.tooltipTitle]}
                visible={visibleMobileTip}
                onAppear={onAppearTip}
                onClose={onCloseMobileTip}
                closeText={trls[TrlKeys.close]}
            >
                {tooltipAdivices}
            </MobileTip>
        </>
    ) : (
        <Modal
            size="large"
            visible={visible}
            onClose={onCloseModal}
            titleDescription={subtitle}
            titleDescriptionStyle="secondary"
            actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
            title={modalTitle}
            footer={<ActionBar primaryActions={rightButtons} secondaryActions={leftButtons} />}
        >
            {modalContent}
        </Modal>
    );
};

export default translation(VacancyAdviceModalMagritte);
