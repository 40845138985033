import { ComponentType, Dispatch, FC, memo, useCallback, useEffect, useState } from 'react';
import { AnyAction } from 'redux';

import VacancyShareModal from 'src/components/HiringManager/VacancyShareModal';

import ArchiveModal from 'src/components/VacancyModals/Archive';
import AutoProlongationModal from 'src/components/VacancyModals/AutoProlongation';
import AutoPublicationPreviewModal from 'src/components/VacancyModals/AutoPublication';
import DisableAutoPublicationModal from 'src/components/VacancyModals/AutoPublication/DisableAutoPublicationModal';
import RemoveAutoUpdateModal from 'src/components/VacancyModals/AutoUpdateModal/Remove';
import UpdatesSchedule from 'src/components/VacancyModals/AutoUpdateModal/UpdatesSchedule';
import BuyOptionModal from 'src/components/VacancyModals/BuyOptionModal';
import CancelUnpremoderatedVacancyModal from 'src/components/VacancyModals/CancelUnpremoderatedVacancyModal';
import HiringPlan from 'src/components/VacancyModals/HiringPlan';
import ProlongateModal from 'src/components/VacancyModals/Prolongation';
import ResponseWithMessage from 'src/components/VacancyModals/ResponseWithMessage';
import TransferModal from 'src/components/VacancyModals/Transfer';
import TransferArchivedVacancies from 'src/components/VacancyModals/TransferArchivedVacancies';
import TransferDraftsModal from 'src/components/VacancyModals/TransferDrafts';
import UnpremoderatedVacancyPreivew from 'src/components/VacancyModals/UnpremoderatedVacancyPreview';
import UpgradeModal from 'src/components/VacancyModals/UpgradeModal';
import VacancyAdviceModal from 'src/components/VacancyModals/VacancyAdviceModal';
import VideoInterviewModal from 'src/components/VacancyModals/VideoInterviewModal';
import {
    ADD_MODAL,
    CLOSE_MODAL,
    MODAL_ARCHIVE,
    MODAL_AUTO_PROLONG,
    MODAL_AUTO_PUBLICATION_DISABLE,
    MODAL_AUTO_PUBLICATION_PREVIEW,
    MODAL_BUY_OPTION,
    MODAL_HIRING_PLAN,
    MODAL_PROLONGATE,
    MODAL_REMOVE_AUTO_UPDATE,
    MODAL_TRANSFER,
    MODAL_TRANSFER_ARCHIVED_VACANCIES,
    MODAL_TRANSFER_DRAFTS,
    MODAL_UPDATES_SCHEDULE,
    MODAL_UPGRADE,
    MODAL_VACANCY_ADVICE,
    MODAL_CANCEL_UNPREMODERATED_VACANCY,
    MODAL_UNPREMODERATED_VACANCY_PREVIEW,
    MODAL_FADING_TIME,
    MODAL_RESPONSE_WITH_MESSAGE,
    MODAL_VACANCY_SHARE,
    MODAL_VIDEOINTERVIEW,
} from 'src/components/VacancyModals/constants';

interface ModalsProps {
    checkedVacancies?: Record<number, number[]>;
    dispatchModal: Dispatch<AnyAction>;
    handleCloseModal: () => void;
    modalData: ModalDataState;
    dispatchCheckVacancy?: () => void;
}

export interface ModalDataState {
    modalType?: string;
    data?: Record<string, unknown>;
}

export interface ModalBaseProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    dispatchModal: Dispatch<AnyAction>;
}

export const modalReducer = (state: ModalDataState, action: AnyAction): ModalDataState => {
    switch (action.type) {
        case ADD_MODAL:
            return action.payload as ModalDataState;
        case CLOSE_MODAL:
            return {};
        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODAL_COMPONENTS: Record<string, ComponentType<any>> = {
    [MODAL_REMOVE_AUTO_UPDATE]: RemoveAutoUpdateModal,
    [MODAL_ARCHIVE]: ArchiveModal,
    [MODAL_AUTO_PROLONG]: AutoProlongationModal,
    [MODAL_PROLONGATE]: ProlongateModal,
    [MODAL_TRANSFER]: TransferModal,
    [MODAL_UPDATES_SCHEDULE]: UpdatesSchedule,
    [MODAL_UPGRADE]: UpgradeModal,
    [MODAL_AUTO_PUBLICATION_DISABLE]: DisableAutoPublicationModal,
    [MODAL_AUTO_PUBLICATION_PREVIEW]: AutoPublicationPreviewModal,
    [MODAL_VIDEOINTERVIEW]: VideoInterviewModal,
    [MODAL_BUY_OPTION]: BuyOptionModal,
    [MODAL_HIRING_PLAN]: HiringPlan,
    [MODAL_TRANSFER_DRAFTS]: TransferDraftsModal,
    [MODAL_TRANSFER_ARCHIVED_VACANCIES]: TransferArchivedVacancies,
    [MODAL_VACANCY_ADVICE]: VacancyAdviceModal,
    [MODAL_CANCEL_UNPREMODERATED_VACANCY]: CancelUnpremoderatedVacancyModal,
    [MODAL_UNPREMODERATED_VACANCY_PREVIEW]: UnpremoderatedVacancyPreivew,
    [MODAL_RESPONSE_WITH_MESSAGE]: ResponseWithMessage,
    [MODAL_VACANCY_SHARE]: VacancyShareModal,
};

const Modals: FC<ModalsProps> = ({
    handleCloseModal,
    dispatchModal,
    dispatchCheckVacancy,
    modalData: { modalType = '', data = {} },
}) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const gracefulCloseModal = useCallback(() => {
        setModalVisible(false);
        setTimeout(handleCloseModal, MODAL_FADING_TIME);
    }, [handleCloseModal]);

    useEffect(() => {
        if (modalType) {
            setModalVisible(true);
        }
    }, [modalType]);

    if (!MODAL_COMPONENTS[modalType]) {
        return null;
    }

    const ModalComponent = MODAL_COMPONENTS[modalType];

    return (
        <ModalComponent
            isVisible={isModalVisible}
            handleCloseModal={gracefulCloseModal}
            dispatchModal={dispatchModal}
            dispatchCheckVacancy={dispatchCheckVacancy}
            {...data}
        />
    );
};

export default memo(Modals);
