interface UserAgentDetails {
    browser: string;
}

export const isSafariUserAgent = (): boolean => {
    if (typeof window?.bloko?.getUserAgentDetails !== 'function') {
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const userAgentInfo = window.bloko.getUserAgentDetails(window.navigator.userAgent) as UserAgentDetails;
    return userAgentInfo?.browser === 'safari';
};
