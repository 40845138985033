import { VacanciesFilters } from 'src/models/employerVacancies/vacancy.types';

export const CHECK_AREA = 'CHECK_AREA';
export const CHECK_MANAGER = 'CHECK_MANAGER';
export const CHECK_DEFINING_PROPERTY = 'CHECK_DEFINING_PROPERTY';
export const CHECK_PFP_PROPERTY = 'CHECK_PFP_PROPERTY';
export const CHECK_VACANCY_PUBLICATION_FORMAT = 'CHECK_VACANCY_PUBLICATION_FORMAT';
export const CHECK_VACANCY_VISIBILITY = 'CHECK_VACANCY_VISIBILITY';
export const CHECK_PREMODERATION = 'CHECK_PREMODERATION';
export const CHECK_AUCTION_STATE = 'CHECK_AUCTION_STATE';
export const CHECK_CLICKME_MULTI_POSTING_STATE = 'CHECK_CLICKME_MULTI_POSTING_STATE';
export const VACANCY_NAME = 'VACANCY_NAME';
export const CLEAR_CHECKS = 'CLEAR';
export const SET_FILTERS = 'SET_FILTERS';

interface Action<T, P> {
    type: T;
    payload: P;
}

export type FilterAction =
    | Action<typeof VACANCY_NAME, string>
    | Action<typeof CHECK_AREA, string[]>
    | Action<typeof CHECK_MANAGER, string[]>
    | Action<typeof CHECK_DEFINING_PROPERTY, string[]>
    | Action<typeof CHECK_PFP_PROPERTY, string[]>
    | Action<typeof CHECK_VACANCY_PUBLICATION_FORMAT, string[]>
    | Action<typeof CHECK_VACANCY_VISIBILITY, string[]>
    | Action<typeof CHECK_PREMODERATION, string[]>
    | Action<typeof CHECK_AUCTION_STATE, string[]>
    | Action<typeof CHECK_CLICKME_MULTI_POSTING_STATE, string[]>
    | Action<typeof CLEAR_CHECKS, undefined>
    | Action<typeof SET_FILTERS, Partial<VacanciesFilters>>;

type FilterReducerType = (state: VacanciesFilters, action: FilterAction) => VacanciesFilters;

const getChecked = (state: string[], value: VacanciesFilters[keyof VacanciesFilters]) => {
    if (Array.isArray(value)) {
        return value;
    }

    if (state.includes(value)) {
        return state.filter((id) => id !== value);
    }

    return state.concat(value);
};

const filterReducer: FilterReducerType = (state, action) => {
    switch (action.type) {
        case VACANCY_NAME:
            return {
                ...state,
                vacancyName: action.payload,
            };

        case CHECK_AREA:
            return {
                ...state,
                areaId: getChecked(state.areaId, action.payload),
            };

        case CHECK_MANAGER:
            return {
                ...state,
                employerManagerId: getChecked(state.employerManagerId, action.payload),
            };

        case CHECK_DEFINING_PROPERTY:
            return {
                ...state,
                vacancyDefiningProperty: getChecked(state.vacancyDefiningProperty, action.payload),
            };

        case CHECK_PFP_PROPERTY:
            return {
                ...state,
                vacancyPfpProperty: getChecked(state.vacancyPfpProperty, action.payload),
            };

        case CHECK_VACANCY_PUBLICATION_FORMAT:
            return {
                ...state,
                vacancyPublicationFormat: getChecked(state.vacancyPublicationFormat, action.payload),
            };

        case CHECK_VACANCY_VISIBILITY:
            return {
                ...state,
                vacancyVisibility: getChecked(state.vacancyVisibility, action.payload),
            };

        case CHECK_PREMODERATION:
            return {
                ...state,
                premoderateStatus: getChecked(state.premoderateStatus, action.payload),
            };

        case CHECK_AUCTION_STATE:
            return {
                ...state,
                auctionState: getChecked(state.auctionState, action.payload),
            };

        case CHECK_CLICKME_MULTI_POSTING_STATE:
            return {
                ...state,
                clickmeMultiPostingState: getChecked(state.clickmeMultiPostingState, action.payload),
            };

        case CLEAR_CHECKS: {
            const { vacancyName, ...restFilters } = state;
            const newFiltersValues = Object.keys(restFilters).reduce(
                (result, filterKey) => {
                    result[filterKey] = [];
                    return result;
                },
                {} as Omit<VacanciesFilters, 'vacancyName'>
            );
            return {
                vacancyName,
                ...newFiltersValues,
            };
        }

        case SET_FILTERS:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default filterReducer;
