import { ScaleLinear } from 'd3-scale';

export type MarketRange = [number, number, number, number];

export interface ChartProps {
    data: MarketRange;
}

export type XAxis = ScaleLinear<number, number>;

export enum SalaryGradient {
    In = 'in',
    Out = 'out',
}

export interface CompensationResult {
    gradient: SalaryGradient | null;
    from: number;
    to: number;
    /**
     * Признак уровень дохода ниже\выше рынка
     */
    isOutOfRange?: boolean;
}

export type RangeToPercent = MarketRange;
