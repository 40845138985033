import { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    success: 'employer.myVacancies.advice.feedback.modal.successNotification',
};

const VacancyAdviceModalFeedbackSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.success]}</>;

export default {
    Element: translation(VacancyAdviceModalFeedbackSuccess),
    kind: NotificationKind.Ok,
    autoClose: true,
};
