import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppStore } from 'src/app/store';
import { ContactsAction, FormValues } from 'src/components/VacancyContactsChangeModal/types';
import { moveManagerVacancies } from 'src/models/employerVacancies/managerVacancies';
import { EmployerManager } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

const MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL = '/employer/movevacancies_changecontacts';

declare global {
    interface FetcherPostApi {
        [MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL]: {
            queryParams: void;
            body: {
                id: number[];
                targetManagerId: number;
                fio: string;
                phone?: string;
                email?: string;
                comment?: string;
                additionalPhone?: string;
                additionalComment?: string;
                updateContactsAction: ContactsAction;
            };
            response: void;
        };
    }
}
export default ({
    vacanciesIds,
    filterEmployerManagerIdList = [],
    managerToTransfer,
    groupId,
    currentGroupManagersIdsSet,
    changeContactFormValues,
    updateContactsAction,
    page = 'employer_vacancies',
}: {
    vacanciesIds: number[];
    filterEmployerManagerIdList?: string[];
    managerToTransfer: EmployerManager;
    groupId?: string;
    currentGroupManagersIdsSet: Set<number>;
    changeContactFormValues: FormValues;
    updateContactsAction: ContactsAction;
    page?: 'employer_vacancies' | 'vacancy';
}): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> => {
    return async (dispatch) => {
        return fetcher
            .postFormData(MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL, {
                id: vacanciesIds,
                targetManagerId: managerToTransfer.managerId,
                updateContactsAction,
                ...changeContactFormValues,
            })
            .then(() => {
                if (page === 'employer_vacancies') {
                    dispatch(
                        moveManagerVacancies({
                            checkedVacancies: vacanciesIds,
                            managerToTransfer,
                            filterEmployerManagerIdList,
                            groupId,
                            groupManagersIds: currentGroupManagersIdsSet,
                        })
                    );
                }
            })
            .catch((err) => {
                throw err;
            });
    };
};
