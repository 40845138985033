import { Dispatch, useCallback, FC, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { Button, useBreakpoint, ButtonMode, ButtonSize } from '@hh.ru/magritte-ui';
import { FolderOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { TriggerType } from 'src/components/ControlsTrigger';
import archiveVacancyApplicantsFeedbackSuccess from 'src/components/Notifications/ArchiveVacancyApplicantsFeedbackSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import ArchiveAction from 'src/components/VacancyActions/ArchiveAction';
import { MODAL_FADING_TIME } from 'src/components/VacancyModals/constants';
import { fetchAndUpdateVacanciesCount } from 'src/models/employerVacancies/vacanciesCount';
import { removeUnpremoderatedVacancyAction, UnpremoderatedVacancy } from 'src/models/unpremoderatedVacancyList';

interface EditButtonProps {
    vacancy: UnpremoderatedVacancy;
    moderatorId: number;
    dispatchModal: Dispatch<AnyAction>;
    onCancel: () => void;
    handleCloseModal: () => void;
}

const ArchiveButton: FC<EditButtonProps> = ({ vacancy, moderatorId, dispatchModal, onCancel, handleCloseModal }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { isMobile } = useBreakpoint();
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const afterSuccessArchiveModal: (params: { isFeedbackSent: boolean; responseQuery: string }) => void = useCallback(
        (params) => {
            dispatch(removeUnpremoderatedVacancyAction({ moderatorId, vacancyId: vacancy.id }));
            void fetchAndUpdateVacanciesCount(dispatch, moderatorId);

            if (params.isFeedbackSent) {
                addNotification(archiveVacancyApplicantsFeedbackSuccess);
            }
        },
        [addNotification, dispatch, moderatorId, vacancy.id]
    );

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    const buttonProps = isMobile
        ? {
              icon: <FolderOutlinedSize24 />,
              mode: 'tertiary' as ButtonMode,
              size: 'large' as ButtonSize,
          }
        : {
              mode: 'secondary' as ButtonMode,
              size: 'small' as ButtonSize,
          };

    return (
        <ArchiveAction
            Component={(props) => (
                <Button
                    style="negative"
                    onClick={() => {
                        handleCloseModal();

                        timer.current = setTimeout(() => {
                            props.onTrigger?.();
                        }, MODAL_FADING_TIME);
                    }}
                    stretched={true}
                    {...buttonProps}
                >
                    {props.children}
                </Button>
            )}
            triggerType={TriggerType.Button}
            dispatchModal={dispatchModal}
            vacanciesIds={[vacancy.id]}
            vacancyName={vacancy.name}
            onArchiveSuccess={afterSuccessArchiveModal}
            onCancel={onCancel}
        />
    );
};

export default ArchiveButton;
