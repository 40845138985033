import { Push } from '@hh.ru/redux-spa-middleware';

import { PublicationProductTypeWithTrls } from 'src/models/prolongateInfo';
import fetcher from 'src/utils/fetcher';

const PROLONGATE_PURCHASE_VACANCY_URL = '/shards/employer/vacancies/prolongate/purchase';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_PURCHASE_VACANCY_URL]: {
            queryParams: void;
            body: {
                products: PublicationProductTypeWithTrls[];
                purchaseParams: {
                    source: string;
                };
                vacancyIdsToBuy: number[];
            };
            response: { purchaseUrl: string };
        };
    }
}

const purchaseVacancy =
    ({
        errorNotification,
        push,
        products,
        purchaseParams,
        vacancyIdsToBuy,
    }: {
        errorNotification?: () => void;
        push: Push;
        products: PublicationProductTypeWithTrls[];
        purchaseParams: {
            source: string;
        };
        vacancyIdsToBuy: number[];
    }) =>
    async (): Promise<void> => {
        let response;
        try {
            response = await fetcher.post(PROLONGATE_PURCHASE_VACANCY_URL, {
                products,
                purchaseParams,
                vacancyIdsToBuy,
            });
            push(response.data.purchaseUrl);
        } catch (error) {
            errorNotification?.();
            throw error;
        }
    };

export default purchaseVacancy;
