import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Text, TooltipHover, useBreakpoint } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MobileTip from 'src/components/VacancyModals/VacancyAdviceModal/MobileTip';
import { noop } from 'src/components/VacancyModals/VacancyAdviceModal/utils';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { DOT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './salary-advice-tooltip.less';

interface SalaryAdviceTooltipProps {
    vacancyId: number;
    analyticEventName: string;
}

const TrlKeys = {
    details: 'Details',
    tooltip: {
        title: 'employer.myVacancies.advice.tooltip.title.salaryAdvice',
        p: 'employer.myVacancies.advice.tooltip.salaryAdvice.p',
        li1: 'employer.myVacancies.advice.tooltip.salaryAdvice.li1',
        li2: 'employer.myVacancies.advice.tooltip.salaryAdvice.li2',
        li3: 'employer.myVacancies.advice.tooltip.salaryAdvice.li3',
    },
    close: 'close',
};

const SalaryAdviceTooltip: TranslatedComponent<SalaryAdviceTooltipProps> = ({ trls, vacancyId, analyticEventName }) => {
    const activatorRef = useRef<HTMLDivElement | null>(null);
    const { isMobile } = useBreakpoint();

    const [visibleMobileTip, onShowMobileTip, onCloseMobileTip] = useOnOffState(false);

    const tipContent = (
        <>
            <p>{trls[TrlKeys.tooltip.p]}</p>
            <ul>
                <li>
                    {DOT}
                    {NON_BREAKING_SPACE}
                    {trls[TrlKeys.tooltip.li1]}
                </li>
                <li>
                    {DOT}
                    {NON_BREAKING_SPACE}
                    {trls[TrlKeys.tooltip.li2]}
                </li>
                <li>
                    {DOT}
                    {NON_BREAKING_SPACE}
                    {trls[TrlKeys.tooltip.li3]}
                </li>
            </ul>
        </>
    );

    const onAppearTip = () => {
        Analytics.sendHHEvent(analyticEventName, { vacancyId });
    };

    return (
        <>
            <span ref={activatorRef} className={styles.salaryAdviceTooltip} onClick={isMobile ? onShowMobileTip : noop}>
                <InfoCircleOutlinedSize16 initial="secondary" />
                {!isMobile && (
                    <Text style="secondary" typography="label-2-regular">
                        {trls[TrlKeys.details]}
                    </Text>
                )}
            </span>
            <TooltipHover placement="right-center" activatorRef={activatorRef} onAppear={onAppearTip}>
                {tipContent}
            </TooltipHover>
            <MobileTip
                title={trls[TrlKeys.tooltip.title]}
                visible={visibleMobileTip}
                onAppear={onAppearTip}
                onClose={onCloseMobileTip}
                closeText={trls[TrlKeys.close]}
            >
                {tipContent}
            </MobileTip>
        </>
    );
};

export default translation(SalaryAdviceTooltip);
