import { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { AxiosError } from 'axios';

import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';

import ChangeContactsForm from 'src/components/VacancyContactsChangeModal/ChangeContactsForm';
import ContactsActionSwitcher from 'src/components/VacancyContactsChangeModal/ContactsActionSwitcher';
import useFetchManagerContacts from 'src/components/VacancyContactsChangeModal/api/useFetchManagerContacts';
import { ContactsAction, FormValues, ResponseError } from 'src/components/VacancyContactsChangeModal/types';
import {
    AVAILABLE_CONTACTS_ACTIONS_MAP,
    EMPTY_VALUE,
    errorConstructor,
    trimFormValues,
} from 'src/components/VacancyContactsChangeModal/utils';

const TrlKeys = {
    newContactsHint: 'vacancy.contacts.change.modal.new.contacts.hint',
    newContactsHintMany: 'vacancy.contacts.change.modal.new.contacts.hint.many',
    hideHint: 'vacancy.contacts.change.modal.hide.hint',
    hideHintMany: 'vacancy.contacts.change.modal.hide.hint.many',
    dontChangeHint: 'vacancy.contacts.change.modal.dont.change.hint',
    dontChangeHintMany: 'vacancy.contacts.change.modal.dont.change.hint.many',

    cantLoadManagerData: 'vacancy.contacts.change.modal.error.cantLoadManagerData',
    moveError: 'employer.myVacancyes.move.error',
};

interface VacancyContactsChangeModalProps {
    vacanciesCount?: number;
    managerId: string;
    onSubmit: (formValues: FormValues, updateContactsAction: ContactsAction) => Promise<void>;
    setError: (error: string) => void;
    type: 'delete' | 'move';
}

const VacancyContactsChangeModal: TranslatedComponent<VacancyContactsChangeModalProps> = ({
    trls,
    vacanciesCount,
    managerId,
    onSubmit,
    setError,
    type,
}) => {
    const [, fetchContacts] = useFetchManagerContacts();
    const [updateContactsAction, setUpdateContactsAction] = useState(ContactsAction.Update);
    const [initialValues, setInitialValues] = useState<FormValues>(EMPTY_VALUE);

    useEffect(() => {
        const getFormData = async () => {
            const [result, error] = await fetchContacts({ managerId });

            if (error) {
                setError(trls[TrlKeys.cantLoadManagerData]);
                return;
            }

            setInitialValues(result);
        };

        void getFormData();
    }, [fetchContacts, managerId, setError, trls]);

    const submitForm = async (formValues: FormValues) => {
        try {
            await onSubmit(trimFormValues(formValues), updateContactsAction);
            return {};
        } catch (error) {
            const axiosError = error as AxiosError<{ errors: ResponseError }>;

            if (!axiosError?.response?.data?.errors) {
                setError(trls[TrlKeys.moveError]);
            }

            return errorConstructor(axiosError?.response?.data?.errors);
        }
    };

    return (
        <FinalForm
            initialValues={initialValues}
            onSubmit={submitForm}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} method="post" id="change-contacts-form">
                    <ContactsActionSwitcher
                        options={AVAILABLE_CONTACTS_ACTIONS_MAP.get(type)}
                        value={updateContactsAction}
                        onChange={setUpdateContactsAction}
                    />
                    <VSpacing default={24} />
                    <ChangeContactsForm
                        actionType={updateContactsAction}
                        initialValues={initialValues}
                        vacanciesCount={vacanciesCount}
                    />
                </Form>
            )}
        />
    );
};

export default translation(VacancyContactsChangeModal);
