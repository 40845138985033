import { useRef, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { select } from 'd3-selection';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChartContext from 'src/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';
import { RANGE_TO_PERCENT } from 'src/components/VacancyView/SalaryMarketRangeChart/constants';
import { ChartProps } from 'src/components/VacancyView/SalaryMarketRangeChart/types';
import withChartContainer from 'src/components/VacancyView/SalaryMarketRangeChart/withChartContainer';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import formatToInt from 'src/utils/formatToInt';

import styles from './ticks-x-axis.less';

const OFFSET_Y = 16; // equal font-size

const TrlKeys = {
    from: 'vacancy.salaryMarketRangeChart.legend.from',
    to: 'vacancy.salaryMarketRangeChart.legend.to',
};

const getTickColor = (salaryRange: SalaryRange) => {
    switch (salaryRange) {
        case SalaryRange.TooWide:
        case SalaryRange.TooLow:
        case SalaryRange.Low:
        case SalaryRange.MatchMarket:
            return [styles.tickColorRed, styles.tickColorOrange, styles.tickColorOrange, styles.tickColorViolet];

        case SalaryRange.High:
        case SalaryRange.TooHigh:
            return [styles.tickColorRed, styles.tickColorGraphite, styles.tickColorGraphite, styles.tickColorViolet];

        default:
            return [styles.tickColorRed, styles.tickColorGreen, styles.tickColorGreen, styles.tickColorViolet];
    }
};

interface TicksXAxisProps extends ChartProps {
    salaryRange: SalaryRange;
}

const DATA_QA_RANGE = ['minimal', 'bottom', 'upper', 'maximal'];

const TicksXAxis: TranslatedComponent<TicksXAxisProps> = ({ data, salaryRange, trls }) => {
    const { xAxis } = useContext(ChartContext);
    const textChartRef = useRef<SVGGElement | null>(null);
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (!textChartRef.current) {
            return undefined;
        }

        const gText = select(textChartRef.current).selectAll('text').data(data).enter();
        const tickColor = getTickColor(salaryRange);

        const tickText = (d: number) => formatToInt(d);
        const tickTextMobile = (d: number, ind: number) => {
            if ([0, 3].includes(ind)) {
                const trlPath = ind === 0 ? TrlKeys.from : TrlKeys.to;
                return `${trls[trlPath]}${NON_BREAKING_SPACE}${String(formatToInt(d))}`;
            }
            return '';
        };

        gText
            .append('text')
            .attr('x', (d, ind) => xAxis(RANGE_TO_PERCENT[ind]))
            .attr('y', OFFSET_Y)
            .text(isMobile ? tickTextMobile : tickText)
            .attr('class', (d, ind) =>
                classnames(isMobile ? styles.ticksXAxisMobile : styles.ticksXAxis, tickColor[ind])
            )
            .attr('data-qa', (_, ind) => `salary-chart-range-${DATA_QA_RANGE[ind]}`);

        return () => {
            gText.selectAll('text').remove();
        };
    }, [xAxis, data, isMobile, salaryRange, trls]);

    return <g ref={textChartRef} />;
};

export default translation(withChartContainer(TicksXAxis));
