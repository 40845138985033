import { AnyAction, Dispatch } from 'redux';

import { vacancyUpdateError } from 'src/components/Notifications/EmployerVacancies';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { updateManagerVacancies } from 'src/models/employerVacancies/managerVacancies';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

const VACANCY_FULL_INFO_URL = '/shards/employer/vacancies/get_full_vacancy';

declare global {
    interface FetcherGetApi {
        [VACANCY_FULL_INFO_URL]: {
            queryParams: {
                vacancyId: number[];
            };
            response: {
                vacancies: Vacancy[];
            };
        };
    }
}

const updateVacancyInfoAfterSuccessAction =
    ({ addNotification, vacancyIds }: { addNotification: AddNotification; vacancyIds: number[] }) =>
    (dispatch: Dispatch<AnyAction>): Promise<{ vacancies: Vacancy[] }> => {
        return fetcher
            .get(VACANCY_FULL_INFO_URL, {
                params: {
                    vacancyId: vacancyIds,
                },
            })
            .then(
                (updatedVacancies) => {
                    dispatch(updateManagerVacancies(updatedVacancies));
                    return updatedVacancies;
                },
                (error) => {
                    addNotification(vacancyUpdateError, {
                        props: { count: vacancyIds.length },
                    });
                    return Promise.reject(error as Error);
                }
            );
    };

export default updateVacancyInfoAfterSuccessAction;
